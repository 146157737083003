<template>
  <menu-list :type="type" :list="favoriteMenuList"></menu-list>
</template>

<script>
import { mapGetters } from "vuex";
import MenuList from "./MenuList";

export default {
  name: "ContentFavorite",
  components: { MenuList },
  data() {
    return {
      type: "FAVORITE",
    };
  },
  computed: {
    ...mapGetters(["favoriteMenuList"]),
  },
};
</script>

<style scoped></style>
