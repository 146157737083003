/**
 * 폼 요소 사이즈
 * @type {{sm_auto: string, xs_auto: string, auto: string, md_auto: string, none: string, xmd: string, md: string, sm2: string, sm: string, lg: string, xs: string, xmd_auto: string, sm2_auto: string}}
 */
export const ITEM_FORM_SIZE = {
  none: '',
  lg: 'form_large',
  md: 'form_medium',
  md_auto: 'form_medium type_auto',
  xmd: 'form_xmedium',
  xmd_auto: 'form_xmedium type_auto',
  sm: 'form_small',
  sm_auto: 'form_small type_auto',
  sm2: 'form_small2',
  sm2_auto: 'form_small2 type_auto',
  sm3: 'form_small3',
  sm3_auto: 'form_small3 type_auto',
  sm4: 'form_small4',
  sm4_auto: 'form_small4 type_auto',
  sm5: 'form_small5',
  sm5_auto: 'form_small5 type_auto',
  xs: 'form_xsmall',
  xs_auto: 'form_xsmall type_auto',
  auto: 'type_auto'
};
