<template>
  <div class="popup_layer_certificate">
    <div class="inner_popup_layer">
      <div class="layer_head">
        <strong class="title">재직/경력 증명서 발급</strong>
        <span class="role_badge">admin</span>
      </div>
      <div class="layer_body">
        <div class="tab_box">
          <ul>
            <li :class="{ active: currentTab === 0 }">
              <a href="javascript:;" @click.prevent="currentTab = 0">발급하기</a>
            </li>
            <li :class="{ active: currentTab === 1 }">
              <a href="javascript:;" @click.prevent="currentTab = 1">내역확인</a>
            </li>
          </ul>
          <button type="button" class="common_white_btn" @click="openLayerUser">사용자 전환</button>
        </div>
        <div v-show="currentTab === 0" class="tab_content">
          <!--증명서 발급 부분 -->
          <div class="box_tbl">
            <table class="tbl_comm type_row">
              <caption class="ir_caption">
                증명서 발급
              </caption>
              <colgroup>
                <col style="width: 150px" />
                <col style="width: auto" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">구성원 <span class="red_txt">*</span></th>
                  <td>
                    <div class="search_wrap">
                      <!-- 포커스인 경우 opt_open 클래스 추가 -->
                      <div class="opt_comm opt_search" :class="{ opt_open: isFocus }">
                        <div class="box_tf">
                          <input
                            v-model="accountId"
                            type="text"
                            class="tf_comm"
                            title="구성원"
                            placeholder="이름 또는 아이디 검색"
                            @focus="isFocus = true"
                            @keyup="userSearch"
                          />
                          <button type="button" class="btn_del" @click="cancel">
                            <span class="img_egroup ico_del">삭제</span>
                          </button>
                          <button class="search_icon">
                            <span class="img_egroup ico_search">검색</span>
                          </button>
                        </div>
                        <!--  포커스 되었을 때 나오는 검색 내역-->
                        <div v-if="userListInfo.length > 0" class="box_opt">
                          <ul class="list_opt">
                            <li v-for="(userInfo, idx) in userListInfo" :key="idx">
                              <a
                                href="javascript:;"
                                class="link_opt"
                                @click="userSelect(userInfo, 'accountId')"
                                >{{ userInfo.accountId }}
                                {{ userInfo.name != null ? `(${userInfo.name})` : "" }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p v-if="isSelect" class="team_name">{{ deptName }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">증명서 종류 <span class="red_txt">*</span></th>
                  <td>
                    <div class="item_form">
                      <span
                        v-if="!hasRetirementDate || (hasRetirementDate && empStatus < 3)"
                        class="radio-box"
                      >
                        <input
                          id="ra1"
                          v-model="documentVo.type"
                          type="radio"
                          name="group1"
                          class="radio-input"
                          value="재직"
                          checked="true"
                        />
                        <label for="ra1" class="radio-label"> 재직증명서 </label>
                      </span>
                      <span
                        v-if="!hasRetirementDate || (hasRetirementDate && empStatus > 1)"
                        class="radio-box"
                      >
                        <input
                          id="ra2"
                          v-model="documentVo.type"
                          type="radio"
                          name="group1"
                          class="radio-input"
                          value="경력"
                        />
                        <label for="ra2" class="radio-label"> 경력증명서 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">정보 노출 여부</th>
                  <td>
                    <div class="item_form">
                      <span class="checkbox-box">
                        <input
                          id="ck1"
                          v-model="documentVo.addressYn"
                          type="checkbox"
                          class="checkbox-input"
                          name="name"
                          true-value="Y"
                          false-value="N"
                        />
                        <label for="ck1" class="checkbox-label"> 주소 노출 </label>
                      </span>
                      <span class="checkbox-box">
                        <input
                          id="ck2"
                          v-model="documentVo.residentNumYn"
                          type="checkbox"
                          class="checkbox-input"
                          name="name"
                          true-value="Y"
                          false-value="N"
                        />
                        <label for="ck2" class="checkbox-label"> 주민등록번호 노출 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">언어 <span class="red_txt">*</span></th>
                  <td>
                    <div class="select_area">
                      <span class="select-box">
                        <select v-model="documentVo.lang" class="select-select">
                          <option value="">선택</option>
                          <template v-for="option in languageList">
                            <option
                              :key="option.value"
                              :value="option.value"
                              :disabled="option.disabled"
                            >
                              {{ option.name }}
                            </option>
                          </template>
                        </select>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">직인 포함 여부 <span class="red_txt">*</span></th>
                  <td>
                    <div class="item_form">
                      <span class="radio-box">
                        <input
                          id="ra3"
                          v-model="documentVo.sealYn"
                          type="radio"
                          name="group2"
                          class="radio-input"
                          value="Y"
                        />
                        <label for="ra3" class="radio-label"> 직인 포함 </label>
                      </span>
                      <span class="radio-box">
                        <input
                          id="ra4"
                          v-model="documentVo.sealYn"
                          type="radio"
                          name="group2"
                          class="radio-input"
                          value="N"
                        />
                        <label for="ra4" class="radio-label"> 직인 비포함 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">비고</th>
                  <td>
                    <input
                      v-model="documentVo.remark"
                      type="text"
                      placeholder="입력해주세요."
                      class="inp_comm"
                      title="비고 입력"
                      maxlength="30"
                      @keyup="lengthCheck"
                    />
                    <span class="txt_count"
                      ><span class="num_count">{{ remarkLength }}</span
                      >/30</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrap_btn">
            <button type="button" class="btn_comm" @click="closeDocumentLayer">닫기</button>
            <!--  비활성화 : disabled, 알랏 컴포넌트 연결, 메세지 이벤트버스로 전달 -->
            <button type="button" class="btn_comm pdf_btn" @click="issuePdf">PDF 발급</button>
          </div>
        </div>
        <div v-show="currentTab === 1" class="tab_content">
          <!-- 조회 검색 부분 -->
          <div class="search_box">
            <ul class="list_filter">
              <li class="date_search">
                <strong>발급기간</strong>
                <span class="radio-box">
                  <input
                    id="ra6"
                    v-model="IssueDateCheck"
                    type="radio"
                    name="group3"
                    class="radio-input"
                    value="ALL"
                    @change="changeDocumentDateCheck"
                  />
                  <label for="ra6" class="radio-label"> 전체 </label>
                </span>
                <span class="radio-box">
                  <input
                    id="ra7"
                    v-model="IssueDateCheck"
                    type="radio"
                    name="group3"
                    class="radio-input"
                    value="A"
                    @change="changeDocumentDateCheck"
                  />
                  <label for="ra7" class="radio-label"> 기간 설정 </label>
                </span>
                <div class="date_search_area">
                  <date-picker
                    v-model="documentHistoryParamForm.searchStartDate"
                    :size="TYPES.DATE_PICKER_SIZE.FULL"
                    :default-value="defaultStartDate"
                    :null-ignore="true"
                    :disabled="IssueDateCheck === 'ALL'"
                    :max-date="documentHistoryParamForm.searchEndDate"
                  />
                  <span class="text_bar">~</span>
                  <date-picker
                    v-model="documentHistoryParamForm.searchEndDate"
                    :size="TYPES.DATE_PICKER_SIZE.FULL"
                    :default-value="defaultEndDate"
                    :null-ignore="true"
                    :disabled="IssueDateCheck === 'ALL'"
                    :min-date="documentHistoryParamForm.searchStartDate"
                  />
                </div>
              </li>
              <li class="person_search">
                <strong>검색</strong>
                <span class="select-box type2">
                  <select
                    v-model="searchDivCheck"
                    class="select-select"
                    @change="changeSearchDivCheck"
                  >
                    <option value="1">구성원</option>
                    <option value="2">문서번호</option>
                  </select>
                </span>
                <div class="search_bar_wrap" :class="{ opt_open: isFocus }">
                  <div class="search_bar_box">
                    <input
                      v-model="searchText"
                      type="text"
                      class="person_input"
                      title="구성원 검색"
                      placeholder="입력하세요."
                      @focus="isFocus = true"
                      @keyup="keyupSearchText"
                    />
                  </div>
                  <!--  포커스 되었을 때 나오는 검색 내역-->
                  <div v-if="userListInfo.length > 0" class="box_opt">
                    <ul class="list_opt">
                      <li v-for="(userInfo, idx) in userListInfo" :key="idx">
                        <a
                          href="javascript:;"
                          class="link_opt"
                          @click="userSelect(userInfo, 'searchText')"
                          >{{ userInfo.accountId }}
                          {{ userInfo.name != null ? `(${userInfo.name})` : "" }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <div class="group_btn">
              <button type="button" @click="DocumenthistorylistSearch">조회</button>
            </div>
          </div>
          <!--총 조회건수 부분 -->
          <div class="total_count_area">
            <p class="total_count">
              총 <span>{{ totalElements }}</span
              >건
            </p>
            <button type="button" class="common_white_btn" @click="excelDownload">
              엑셀 다운로드
            </button>
          </div>
          <!-- 발급내역 표 -->
          <div class="box_tbl">
            <table class="tbl_comm">
              <caption class="ir_caption">
                발급내역 목록
              </caption>
              <colgroup>
                <col style="width: 5%" />
                <col style="width: 11%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 16%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 5%" />
                <col style="width: 6%" />
                <col style="width: 5%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" rowspan="2">NO</th>
                  <th scope="col" colspan="3">문서정보</th>
                  <th scope="col" colspan="4">구성원 정보</th>
                  <th scope="col" colspan="3">노출여부</th>
                  <th scope="col" rowspan="2">비고</th>
                  <th scope="col" rowspan="2">발급일자</th>
                </tr>
                <tr>
                  <th scope="col">문서번호</th>
                  <th scope="col">구분</th>
                  <th scope="col">언어</th>
                  <th scope="col">구성원</th>
                  <th scope="col">소속</th>
                  <th scope="col">직위</th>
                  <th scope="col">직책</th>
                  <th scope="col">주소</th>
                  <th scope="col">주민번호</th>
                  <th scope="col">직인</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(userInfo, idx) in documentHistoryList" :key="idx">
                  <td>{{ userInfo.idx }}</td>
                  <td>{{ userInfo.documentNum }}</td>
                  <td>{{ userInfo.type }} 증명서</td>
                  <td>{{ userInfo.lang }}</td>
                  <td>{{ userInfo.userNameId }}</td>
                  <td>{{ userInfo.deptName }}</td>
                  <td>{{ userInfo.position || "-" }}</td>
                  <td>{{ userInfo.duty || "-" }}</td>
                  <td>{{ userInfo.addressYn }}</td>
                  <td>{{ userInfo.residentNumYn }}</td>
                  <td>{{ userInfo.sealYn }}</td>
                  <td>
                    <!-- 툴팁 부분 -->
                    <div class="tooltip_box">
                      <p>{{ userInfo.remark }}</p>
                      <div class="tooltip_text">{{ userInfo.remark || "-" }}</div>
                    </div>
                  </td>
                  <td>
                    {{ setDate(userInfo.createDate) }}
                  </td>
                </tr>
                <!--                  발급 이력 없을 때 -->
                <tr v-if="documentHistoryList.length === 0">
                  <td colspan="12" class="empty_data">
                    <div class="inner_g">
                      <span class="ico_info_type2"></span>
                      <p class="txt_empty">발급 이력이 없습니다.</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <dk-pagination
            v-if="page.pageCount > page.pageSize"
            :current="page.current"
            :page-count="page.pageCount"
            :page-size="page.pageSize"
            :range-size="page.rangeSize"
            @select="onClickTurnPage"
          />
        </div>
      </div>
      <!-- 닫기 버튼 -->
      <button class="layer-btn-close" @click="closeDocumentLayer">
        <SvgIcon class="svg--type-close" :viewBox="'0 0 32 32'"><IconClose /></SvgIcon>
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import { IconClose } from "@/assets/svg";
import { EventBus } from "../plugins/eventBus";
import LocalStorageService from "../plugins/LocalStorageService";
import { mapActions, mapState } from "vuex";
import Api from "@/api";
import DkPagination from "@/components/common/pagination/DkPagination";
import DatePicker, { DATE_PICKER_SIZE } from "@/components/common/DatePicker";
import * as types from "@/store/mutation-type";
import moment from "moment";

const localStorageService = LocalStorageService.getService();
var timer;

export default {
  name: "DocumentLayer",
  components: {
    SvgIcon,
    IconClose,
    DkPagination,
    DatePicker,
  },
  data() {
    return {
      hasRetirementDate: false,
      currentTab: 0,
      isFocus: false,
      isSelect: false,
      retirementYn: "",
      empStatus: "",
      empIdName: "",
      accountId: "",
      userListInfo: [],
      remarkLength: 0,
      documentVo: {
        accountId: "",
        name: "",
        companyId: "",
        type: "재직",
        adminYn: "Y",
        residentNumYn: "N",
        addressYn: "N",
        sealYn: "Y",
        lang: "",
        remark: "",
        position: "",
      },
      documentHistoryPage: [],
      documentHistoryList: [],
      IssueDateCheck: "ALL",
      IssueDateStart: "",
      IssueDateEnd: "",
      searchDivCheck: "1",
      searchText: "",
      documentHistoryParamForm: {
        accountId: "",
        documentNum: "",
        searchStartDate: "",
        searchEndDate: "",
        page: 1,
        size: 5,
      },
      totalElements: 0,
      page: {
        current: 1,
        pageCount: 0,
        pageSize: 5,
        rangeSize: 10,
      },
      excelDownloadFlag: false,
      TYPES: {
        DATE_PICKER_SIZE,
      },
      defaultStartDate: "",
      defaultEndDate: "",
      timeout: null,
    };
  },
  computed: {
    ...mapState({
      languageList: (state) => {
        return state.languageList.map((item) => {
          return {
            value: item.code,
            name: item.name,
          };
        });
      },
    }),
  },
  created() {
    this.getLanguageList();
  },
  methods: {
    ...mapActions(["getUserSearch", "getNoticeList", "getLanguageList"]),
    closeDocumentLayer() {
      this.$emit("closeDocumentLayer");
    },
    setDate(date) {
      const year = date[0];
      let month = date[1] > 10 ? date[1] : `0${date[1]}`;
      const day = date[2] > 10 ? date[2] : `0${date[2]}`;
      return `${year}-${month}-${day}`;
    },
    async issuePdf() {
      let flag = true;
      if (this.documentVo.accountId === "") {
        EventBus.$emit("open:documentAlert", {
          message: "구성원을 선택해주세요.",
        });
        flag = false;
      }

      if (flag && this.documentVo.lang === "") {
        EventBus.$emit("open:documentAlert", {
          message: "언어를 선택해주세요.",
        });
        flag = false;
      }
      if (flag) {
        await Api.getIssuePdf(this.documentVo)
          .then((res) => {
            if (res.status === 200 && res.data.result !== null) {
              const fileName = res.data.result;
              this.cancel();
              setTimeout(() => {
                this.fileDown(fileName);
              }, 500);
            } else {
              console.log("자체 500 error");
              EventBus.$emit("open:documentAlert", {
                message: res.data.message.length > 1 ? res.data.message : "관리자에게 문의하세요.",
              });
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }
    },
    async fileDown(fileName) {
      console.log("fileName2 :" + fileName);
      window.open("/api/document/fileDown?fileName=" + fileName);
      await Api.fileDown({ fileName })
        .then((res) => {
          if (res.status === 200) {
            EventBus.$emit("open:documentAlert", {
              message: "신청한 문서가 PDF파일로 다운로드 되었습니다.",
            });
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    // 디바운스
    debounce(func, wait) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(func, wait);
      return this.timeout;
    },
    //사용자 검색
    userSearch(e) {
      this.debounce(() => {
        this.getUserData(e);
      }, 200);
    },
    async getUserData(e) {
      const val = e.target.value;
      if (val === "") {
        this.userListInfo = [];
        return false;
      }
      const response = await Api.getUserSearch(val);
      console.log("response", response);
      this.userListInfo = response.data.result;
    },
    //사용자 선택
    userSelect(userInfo, type) {
      if (type === "accountId") {
        this.accountId =
          userInfo.name !== null
            ? userInfo.accountId + " (" + userInfo.name + ")"
            : userInfo.accountId;
      } else {
        this.documentHistoryParamForm.accountId = userInfo.accountId;
        this.searchText =
          userInfo.name !== null
            ? userInfo.accountId + " (" + userInfo.name + ")"
            : userInfo.accountId;
      }
      this.documentVo.accountId = userInfo.accountId;
      this.documentVo.name = userInfo.name;
      this.documentVo.position = userInfo.position;
      this.userListInfo = [];
      this.isFocus = false;
      this.deptName = userInfo.deptName;
      this.retirementYn = userInfo.retirementYn;
      this.retirementDate = userInfo.retirementDate;
      this.empStatus = userInfo.empStatus;
      this.isSelect = true;
      if (userInfo.retirementYn === "Y") {
        this.documentVo.type = "경력";
      }
      this.hasRetirementDate = true;
      console.log(`userInfo.retirementDate ${userInfo.retirementDate}`);
      console.log("userInfo.retirementDate", userInfo.retirementDate);
      console.log("userInfo.retirementDate" + userInfo.retirementDate);
    },
    //취소 버튼
    cancel() {
      this.hasRetirementDate = false;
      this.documentVo.accountId = "";
      this.documentVo.name = "";
      this.accountId = "";
      this.empIdName = "";
      this.userListInfo = [];
      this.retirementDate = "";
      this.retirementYn = "";
      this.documentVo.type = "재직";
      this.documentVo.residentNumYn = "N";
      this.documentVo.addressYn = "N";
      this.documentVo.sealYn = "";
      this.documentVo.lang = "";
      this.documentVo.remark = "";
      this.isSelect = false;
      this.isFocus = false;
    },
    //취소 버튼
    lengthCheck(e) {
      this.remarkLength = e.target.value.length;
    },
    //재직/경력 증명서 발급 조회
    async DocumenthistorylistSearch(e) {
      this.documentHistoryParamForm.page = 1;
      if (this.IssueDateCheck === "ALL") {
        this.documentHistoryParamForm.searchStartDate = "";
        this.documentHistoryParamForm.searchEndDate = "";
      }

      await Api.getDocumentHistoryList(this.documentHistoryParamForm)
        .then((res) => {
          const resData = res.data.result;
          const contData = resData.content;
          const _idxStart = resData.totalElements - resData.number * this.page.pageSize;
          let _idxCount = 0;
          let _tmp;
          this.page.current = resData.number + 1;
          this.page.pageCount = resData.totalElements;
          this.totalElements = resData.totalElements;

          this.documentHistoryList = [];
          this.searchText = "";
          contData.forEach((list) => {
            _tmp = {
              idx: _idxStart - _idxCount,
              createDe: this.formatYYYYMMDD(list.createDate),
              userNameId: list.accountId + "(" + list.name + ")",
              ...list,
            };
            _idxCount += 1;
            this.documentHistoryList.push(_tmp);
          });
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    //발급기간 구분 변경 이벤트
    changeDocumentDateCheck(e) {
      if (this.IssueDateCheck === "ALL") {
        this.documentHistoryParamForm.searchStartDate = "";
        this.documentHistoryParamForm.searchEndDate = "";
      } else {
        this.documentHistoryParamForm.searchStartDate = moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");
        this.documentHistoryParamForm.searchEndDate = moment().add(1, "days").format("YYYY-MM-DD");
      }
    },
    //구성원/문서 변경 이벤트
    changeSearchDivCheck(e) {
      console.log("changeSearchDivCheck " + this.searchText);
      if (e.target.value === "1") {
        this.documentHistoryParamForm.accountId = this.searchText;
        this.documentHistoryParamForm.documentNum = "";
      } else {
        this.documentHistoryParamForm.accountId = "";
        this.documentHistoryParamForm.documentNum = this.searchText;
      }
    },
    //텍스트 입력 이벤트
    keyupSearchText(e) {
      console.log("keyupSearchText " + this.searchText);
      if (this.searchDivCheck === "1") {
        this.documentHistoryParamForm.documentNum = "";
        this.userSearch(e);
      } else {
        this.documentHistoryParamForm.accountId = "";
        this.documentHistoryParamForm.documentNum = e.target.value;
      }
    },
    formatYYYYMMDD(val, falsyText = "-") {
      if (this.isFalsy(val)) return falsyText;
      return moment(val).format("YYYY.MM.DD");
    },
    /**
     * falsy 값 체크
     * @param target: 체크 대상
     * @param includeZero: 0도 false로 볼지 여부
     * @returns {boolean}
     */
    isFalsy(target, includeZero) {
      const zero = includeZero ? target === 0 || target === "0" : false;
      return !!(
        target === "" ||
        target === null ||
        target === undefined ||
        target === "NaN" ||
        zero
      );
    },
    onClickTurnPage(page) {
      this.fetchDraftReference(page);
    },
    async fetchDraftReference(page) {
      this.documentHistoryParamForm.page = page;

      const option = {
        offset: page || this.page.current,
        limit: this.page.pageSize,
      };

      const filter = Object.assign({}, this.filter, option);
      // console.log(filter);
      try {
        await Api.getDocumentHistoryList(this.documentHistoryParamForm)
          .then((res) => {
            const resData = res.data.result;
            const contData = resData.content;
            const _idxStart = resData.totalElements - resData.number * this.page.pageSize;
            let _idxCount = 0;
            let _tmp;
            this.page.current = resData.number + 1;
            this.page.pageCount = resData.totalElements;
            this.totalElements = resData.totalElements;

            this.documentHistoryList = [];
            contData.forEach((list) => {
              _tmp = {
                idx: _idxStart - _idxCount,
                createDe: this.formatYYYYMMDD(list.createDate),
                userNameId: list.accountId + "(" + list.name + ")",
                ...list,
              };
              _idxCount += 1;
              this.documentHistoryList.push(_tmp);
            });
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } catch (e) {
        console.log(e);
      }
    },
    openLayerUser() {
      this.$emit("openLayerUser");
    },
    /**
     * 엑셀 다운로드
     * @return {boolean}
     */
    excelDownload() {
      if (this.excelDownloadFlag) return false;
      this.excelDownloadFlag = true;

      Api.getDocumentHistoryExcel(this.documentHistoryParamForm)
        .then((res) => {
          this.makeBlobExelDown(res);
          this.excelDownloadFlag = false;
        })
        .catch((error) => {
          console.log(error, "error");
          this.excelDownloadFlag = false;
        });
    },
    makeBlobExelDown(res, name = "") {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const blobURL = window.webkitURL
        ? window.webkitURL.createObjectURL(blob)
        : window.URL
        ? window.URL.createObjectURL(blob)
        : window.createObjectURL(blob);
      const fileName = name || res.headers["content-disposition"].split("filename=")[1];

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // IE 이외
        let link = document.createElement("a");
        link.href = blobURL;
        link.target = "_self";
        link.download = fileName;

        link.click();
        // 파일 다운로드 후 element, blob 삭제
        setTimeout(() => {
          link = null; // attach한 element가 없으므로 null을 넣어 garbage collect 대상으로 만듬
          window.URL.revokeObjectURL(blobURL);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      border-radius: 50%;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border : {
        width: 1px;
        style: solid;
        color: #d9d9d9;
      }
      background-color: #ffffff;
      border-radius: 50%;
    }
    &::after {
      left: 50%;
      top: 50%;
      width: 6px;
      height: 6px;
      background-color: transparent;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::before {
        border-color: #fde0e8;
      }
    }
    &:checked {
      &::before {
        background-color: #fde0e8;
        border-color: #fde0e8;
      }
      &::after {
        background-color: #333333;
      }
      & + .radio-label {
        color: #3c3c3c;
      }
    }
    &:disabled {
      &::before {
        border-color: #e1e1e1;
        background-color: #f5f5f5;
      }
    }
  }
  &-label {
    display: inline-block;
    padding: 0 8px;
    color: #777777;
    font : {
      size: 13px;
      weight: 500;
    }
    vertical-align: middle;
  }
}
.checkbox {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border : {
        width: 1px;
        style: solid;
        color: #d9d9d9;
        radius: 2px;
      }
      background-color: #ffffff;
    }
    &::after {
      left: 50%;
      top: 50%;
      width: 11px;
      height: 8px;
      background {
        color: transparent;
      }
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::before {
        border-color: #fde0e8;
      }
    }
    &:checked {
      &::before {
        background-color: #fde0e8;
        border-color: #fde0e8;
      }
      &::after {
        background: {
          image: url(~@/assets/svg/iconCheck.svg);
          position: 0 0;
          repeat: no-repeat;
          size: 11px 8px;
        }
      }
      & + .checkbox-label {
        color: #3c3c3c;
      }
    }
    &:disabled {
      &::before {
        border-color: #e1e1e1;
        background-color: #f5f5f5;
      }
      &::after {
        filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(106%) contrast(85%);
      }
    }
  }
  &-label {
    display: inline-block;
    padding: 0 8px;
    color: #777777;
    font : {
      size: 13px;
      weight: 500;
    }
    vertical-align: middle;
  }
}
svg {
  &--type {
    &-close {
      --svg-width: 32px;
      --svg-fiil: #000000;
    }
  }
}
</style>
