<template>
  <div v-if="isOpen" class="popup_layer">
    <div class="inner_popup_layer">
      <div class="layer_body">
        <p class="txt_popup">
          {{ alertMessage }}
        </p>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button type="confirm" class="btn_comm btn_comm2" @click="apply">{{ $t("message.btn_confirm") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../plugins/eventBus";

export default {
  name: "Alert",
  data() {
    return {
      alertMessage: "", // 알럿 메세지
      isOpen: false, // 활성화여부
    };
  },
  mounted() {
    EventBus.$on("open:alert", this.openAlert);
  },
  methods: {
    openAlert(data) {
      this.alertMessage = data.message;
      this.isOpen = true;
    },
    apply() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>

</style>
