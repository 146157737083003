<template>
  <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1003 9C12.8242 9 12.6003 9.22386 12.6003 9.5V14.604L10.9454 12.9558C10.7519 12.7631 10.4391 12.7633 10.2459 12.9561C10.0517 13.1498 10.0519 13.4643 10.2462 13.6578L12.7093 16.1108C12.8295 16.2305 12.9958 16.2758 13.1509 16.2466C13.3059 16.2757 13.4722 16.2305 13.5924 16.1108L16.0555 13.6578C16.2498 13.4643 16.2499 13.1498 16.0558 12.9561C15.8626 12.7633 15.5498 12.7631 15.3563 12.9558L13.6003 14.7046V9.5C13.6003 9.22386 13.3765 9 13.1003 9ZM9.00043 17.5506C9.00043 17.2744 9.22428 17.0506 9.50043 17.0506H16.8259C17.1021 17.0506 17.3259 17.2744 17.3259 17.5506C17.3259 17.8267 17.1021 18.0506 16.8259 18.0506H9.50043C9.22428 18.0506 9.00043 17.8267 9.00043 17.5506Z"
      fill="white"
    />
    <path
      d="M1.00043 18V3.42424C1.00043 2.36151 1.86194 1.5 2.92467 1.5H16.1147C16.6824 1.5 17.2332 1.6932 17.6765 2.04783L24.0622 7.15637C24.6552 7.6308 25.0004 8.34908 25.0004 9.10854V18C25.0004 19.3807 23.8811 20.5 22.5004 20.5H3.50043C2.11972 20.5 1.00043 19.3807 1.00043 18Z"
      stroke="white"
    />
    <path
      d="M16.0004 7.4V1.78103C16.0004 1.27232 16.5937 0.99443 16.9845 1.32009L24.2366 7.36344C24.4952 7.57899 24.3428 8 24.0061 8H16.6004C16.2691 8 16.0004 7.73137 16.0004 7.4Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "IconDocument",
};
</script>
