<template>
  <div class="box_category">
    <div class="box_head">
      <slot name="head"></slot>
    </div>
    <div class="box_cont" :class="{ none_cont: isEmptyData }">
      <slot name="cont"></slot>
      <slot v-if="isEmptyData" name="contEmpty"></slot>
    </div>
    <div class="group_btn">
      <slot name="side"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxCategory",
  props: {
    isEmptyData: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
