import decode from "jwt-decode";

const LocalStorageService = (function () {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }

  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("vuex");
  }

  function _setUserId(id) {
    return localStorage.setItem("user_id", id);
  }
  function _getUserId() {
    return localStorage.getItem("user_id");
  }
  function _clearUserId() {
    return localStorage.removeItem("user_id");
  }
  function _setCasFlag(boolean) {
    localStorage.setItem("cas_flag", boolean);
  }
  function _getCasFlag() {
    return localStorage.getItem("cas_flag");
  }
  function _clearCasFlag() {
    localStorage.removeItem("cas_flag");
  }
  /**
   * AccessToken Decode 변환 반환
   * @returns {any | undefined}
   * @private
   */
  const _getDecodeAccessToken = () => {
    return decode(localStorage.getItem("access_token"));
  };
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setUserId: _setUserId,
    getUserId: _getUserId,
    clearUserId: _clearUserId,
    setCasFlag: _setCasFlag,
    getCasFlag: _getCasFlag,
    clearCasFlag: _clearCasFlag,
    getDecodeAccessToken: _getDecodeAccessToken,
  };
})();
export default LocalStorageService;
