<template>
  <div class="content-aside" :class="{ aside_fold: isAsideFold }">
    <h2 class="screen_out">부가정보</h2>
    <div v-if="bannerDisplay.length < 1" class="box_logo">
      <h3 class="screen_out">공지</h3>
      <img src="../../../assets/images/pc/m640/logo_helinox_big.png" class="img_g" alt="Helinox" />
    </div>
    <div v-if="bannerDisplay.length > 0 && showSwiper" class="area_notice">
      <h3 class="screen_out">공지</h3>
      <!-- Swiper -->
      <swiper ref="bannerSwiper" :options="swiperOptionBanner">
        <swiper-slide v-for="(item, index) in bannerDisplay" :key="index">
          <div
            class="notice_board"
            :class="[{ 'hand-cursor': item.url }, { img_box: item.type === 'IMG' }]"
            @click="moveUrl(item.url)"
          >
            <div v-if="item.type === 'IMG'" class="img_area">
              <img :src="item.imgUrl" :alt="item.fileName" />
            </div>
            <div v-else class="text_area">
              <p class="tit_notice">{{ item.title }}</p>
              <span class="txt_notice">
                {{ item.titleSub }}
              </span>
            </div>
          </div>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
        <div v-show="bannerDisplay.length > 1" slot="button-prev" class="swiper-button-prev"></div>
        <div v-show="bannerDisplay.length > 1" slot="button-next" class="swiper-button-next"></div>
      </swiper>
    </div>
    <!-- 공지사항이 없을 때, area_board에 no_data_board 클래스 추가 -->
    <!-- TODO vue2에서 옵셔널 체이닝 사용 시 에러나는 이슈로 인해서 수정 정상동작하는지 확인 필요  -->
    <div class="wrap_scroll">
      <!-- 공지사항 팝업 레이어 -->
      <NoticeLayer v-if="showNoticeLayer" @closeNoticeLayer="closeNoticeLayer" />
      <!-- 날씨 -->
      <div v-if="userInfo.companyId === 1" class="area_weather">
        <h3 class="screen_out">날씨</h3>
        <swiper ref="weatherSwiper" :options="swiperOptionWeather">
          <swiper-slide v-for="(item, index) in weather" :key="index">
            <div class="info_weather">
              <span class="ico_wm" :class="item.weatherIconName">{{ item.weatherIconName }}</span>
              <span class="txt_temper">{{ item.todayTemperature }}°C</span>
              <span class="txt_site">{{ item.region }}</span>
              <span class="txt_temper2">{{ item.temperatureComparedToYesterday }}</span>
              <span class="info_date">{{ item.todaysWeather }}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-if="userInfo.companyId !== 1" style="border-top: 1px solid #ededed"></div>
      <!-- // 날씨 -->
      <!-- 캘린더 -->
      <div class="area_calendar">
        <h3 class="screen_out">캘린더</h3>
        <v-calendar
          :locale="langCode"
          :attributes="attributes"
          :formats="{ title: 'YYYY.MM' }"
          title-position="left"
          is-expanded
          @update:from-page="getCalendar"
          @dayclick="getSelectDate"
        ></v-calendar>
      </div>
      <!-- // 캘린더 -->
      <div class="area_schedule">
        <tab-list v-model="selectTabIdxSchedule" :list="listTabsSchedule" />
        <!-- 탭: 주요일정 -->
        <div v-show="selectTabIdxSchedule === 0">
          <h3 class="screen_out">{{ selectDate }} {{ $t("message.holiday_L_1") }}</h3>
          <div v-if="mainEventList.length == 0" class="txt_none">
            {{ $t("message.holiday_D_1") }}
          </div>
          <ul v-if="mainEventList.length > 0" class="list_schedule">
            <li v-for="(item, index) in mainEventList" :key="index">
              <span class="item_schedule">
                <span class="thumb_schedule">
                  <span class="ico_comm ico_schedule"></span>
                </span>
                <span class="txt_date">{{ item.startDateTime }} ~ {{ item.endDateTime }}</span>
                <span class="txt_schedule">
                  {{ item.title }}
                </span>
              </span>
            </li>
          </ul>
        </div>
        <!-- // 탭: 주요일정 -->
        <!-- 탭: 오늘의 일정 -->
        <div v-show="selectTabIdxSchedule === 1">
          <h3 class="screen_out">{{ $t("message.holiday_T_1") }}</h3>
          <div v-if="filteredTodayEventList.length == 0" class="txt_none">
            {{ $t("message.holiday_D_2") }}
          </div>
          <ul v-if="filteredTodayEventList.length > 0" class="list_schedule">
            <template v-for="item in filteredTodayEventList">
              <li v-if="item.startDate === selectDay">
                <span class="item_schedule">
                  <span class="thumb_schedule">
                    <span class="ico_comm ico_schedule"></span>
                  </span>
                  <span class="txt_date">{{ item.startDateTime }} ~ {{ item.endDateTime }}</span>
                  <span class="txt_schedule">
                    {{ item.title }}
                  </span>
                </span>
              </li>
            </template>
          </ul>
        </div>
        <!-- // 탭: 오늘의 일정 -->
      </div>
    </div>
  </div>
</template>

<script>
import { swiper } from "vue-awesome-swiper";
import { mapGetters, mapActions, mapState } from "vuex";
import NoticeLayer from "@/components/main/aside/NoticeLayer";
import TabList from "@/components/common/tab/TabList";
import { getBoardId } from "@/utils/common";
import { Trans } from "@/translations";

export default {
  name: "MainAside",
  components: {
    swiper,
    NoticeLayer,
    TabList,
  },
  data() {
    return {
      selectDate: "",
      selectTabIdxSchedule: 0,
      showNoticeLayer: false,
      swiperOptionBanner: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        allowTouchMove: false,
      },
      swiperOptionWeather: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
      },
      weatherIcon: {
        맑음: "ico_wm01",
        구름조금: "ico_wm02",
        구름많이: "ico_wm03",
        흐림: "ico_wm04",
        갬: "ico_wm05",
        점점흐려짐: "ico_wm06",
        "구름/비": "ico_wm07",
        흐린후비: "ico_wm10",
        비: "ico_wm11",
        비온후갬: "ico_wm15",
        눈: "ico_wm18",
        흐린후눈: "ico_wm21",
        맑음후눈: "ico_wm23",
        눈또는비: "ico_wm26",
        흐린뒤눈온후비: "ico_wm29",
        "눈비후 갬": "ico_wm36",
        뇌우: "ico_wm39",
        안개: "ico_wm40",
        우박: "ico_wm41",
      },
      isAsideFold: false,
      selectDay: "",
      showSwiper: false,
    };
  },

  computed: {
    ...mapGetters([
      "weathers",
      "eventHoliday",
      "bannerDisplay",
      "noticeList",
      "mainEventList",
      "todayEventList",
    ]),
    ...mapState(["user"]),
    ...mapGetters(["isAdmin", "userInfo", "workTime", "userPoint", "userVacation"]),
    langCode() {
      return Trans.currentLanguage;
    },
    attributes() {
      let attr = [
        {
          dot: true,
          dates: [],
        },
        {
          dot: "yellow",
          dates: [],
        },
      ];
      this.mainEventList.forEach(function (item) {
        attr[0].dates.push(new Date(item.startDate));
      });
      this.todayEventList.forEach(function (item) {
        attr[1].dates.push(new Date(item.startDate));
      });
      return attr;
    },
    weather() {
      return this.getWeatherData(this.weathers);
    },
    swiper() {
      return this.$refs.bannerSwiper.swiper;
    },
    listTabsSchedule() {
      return [
        { label: `${this.selectDate} ${this.$t("message.holiday_L_1")}` },
        { label: this.$t("message.holiday_T_1") },
      ];
    },
    filteredTodayEventList() {
      return this.todayEventList.filter((item) => {
        return item.startDate === this.selectDay;
      });
    },
  },
  watch: {
    bannerDisplay: {
      immediate: false,
      handler(arr) {
        // 배너 수정 시 업데이트되지 않는 이슈 해소
        if (arr.length > 0) {
          this.showSwiper = false;
          this.$nextTick(() => {
            this.showSwiper = true;
          });
        }
      },
    },
  },
  created() {
    const response = axios.get(`${this.$apiPath.USER}`);
    response
      .then((res) => {
        const googleAccessToken = res.data.result.googleAccessToken;
        if (googleAccessToken === null) {
          this.openAuthGoogle();
        } else {
          this.getCalendar();
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  },
  methods: {
    getBoardId,
    ...mapActions(["getEventHoliday", "getNoticeDetail", "getEvent", "getUser"]),
    getWeatherData(weathers) {
      if (weathers) {
        let weatherData = [];
        weathers.forEach((data) => {
          let tempData = [];
          tempData.region = data.region;
          tempData.todayTemperature = data.todayTemperature;
          tempData.temperatureComparedToYesterday =
            data.temperatureComparedToYesterday < 0
              ? `${this.$t("message.lowTemperature", {
                  temp: data.temperatureComparedToYesterday,
                })}`
              : `${this.$t("message.highTemperature", {
                  temp: data.temperatureComparedToYesterday,
                })}`;
          tempData.weatherIconName =
            this.weatherIcon[
              Object.keys(this.weatherIcon).find((key) => {
                if (key === data.weatherIconName) {
                  return key;
                }
              })
            ];
          tempData.todaysWeather = this.getWeatherToday();
          weatherData.push(tempData);
        });
        return weatherData;
      }
    },
    getWeatherToday() {
      const week = [
        this.$t("date.sun"),
        this.$t("date.mon"),
        this.$t("date.tue"),
        this.$t("date.wed"),
        this.$t("date.thu"),
        this.$t("date.fri"),
        this.$t("date.sat"),
      ];
      const today = new Date();
      const dayOfWeek = week[today.getDay()];
      return `${today.getMonth() + 1}.${today.getDate()} (${dayOfWeek})`;
    },
    async getSchedule(data) {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;

      if (data) {
        year = data.year;
        month = data.month;
      }
      month = month.toString().length < 2 ? "0" + month : month;

      try {
        await this.getEventHoliday({ workMonth: month, workYear: year });
        this.selectDate = `${year}.${month}`;
      } catch (error) {
        return error;
      }
    },
    moveUrl(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    isShowNoticeLayer(postId) {
      const boardId = this.getBoardId();
      this.getNoticeDetail({ postId, boardId });
      this.showNoticeLayer = !this.showNoticeLayer;
    },
    closeNoticeLayer() {
      this.showNoticeLayer = false;
    },
    asideFold() {
      this.isAsideFold = !this.isAsideFold;
    },
    // 구글 계정 로그인 팝업
    openAuthGoogle() {
      let width = 700;
      let height = 600;
      let left = screen.width ? (screen.width - width) / 2 : 0;
      let top = screen.height ? (screen.height - height) / 2 : 0;
      const attr = `top=${top},left=${left},width=${width},height=${height},resizable=no,status=no`;

      const authUrl = process.env.VUE_APP_GOOGLE_AUTH_URL;
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
      const redirectUri = process.env.VUE_APP_PC_URL + "/googleSighInSuccess";
      const scope = process.env.VUE_APP_GOOGLE_SCOPE;

      const googleAuth = `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`;

      window.open(googleAuth, "", attr);
    },
    // 캘린더 일정 목록 조회 API
    async getEventList(calendarCode, startDate, endDate) {
      try {
        await this.getEvent({ calendarCode: calendarCode, startDate: startDate, endDate: endDate });
      } catch (error) {
        return error;
      }
    },
    // 캘린더 일정 목록 조회
    async getCalendar(data) {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();

      if (data) {
        year = data.year;
        month = data.month;
      }
      let lastDate = new Date(year, month, 0).getDate();

      month = month.toString().length < 2 ? "0" + month : month;
      lastDate = lastDate.toString().length < 2 ? "0" + lastDate : lastDate;
      day = day.toString().length < 2 ? "0" + day : day;

      const firstDay = `${year}-${month}-01`;
      const lastDay = `${year}-${month}-${lastDate}`;
      const today = `${year}-${month}-${day}`;

      this.selectDate = `${year}.${month}`;
      this.selectDay = today;

      this.getEventList("MAIN", firstDay, lastDay);
      this.getEventList("TODAY", firstDay, lastDay);
    },
    getSelectDate(day) {
      this.selectDay = day.id;
      console.log(this.selectDay);
    },
  },
};
</script>

<style scoped>
.hand-cursor {
  cursor: pointer;
}
.v-calendar-style /deep/ .vc-weeks {
  pointer-events: none;
}
</style>
<style lang="scss" scoped>
.svg {
  &--type {
    &-fold {
      --svg-width: 33px;
    }
  }
}
</style>
