<template>
  <div class="content-article">
    <h2 class="screen_out">카테고리</h2>
    <div class="inner_main">
      <div class="area_category">
        <content-work />
        <content-office />
        <content-favorite />
      </div>
      <div class="area_category">
        <content-notice />
        <content-approval />
        <content-mail />
      </div>
    </div>
  </div>
</template>

<script>
import ContentWork from "./ContentWork";
import ContentOffice from "./ContentOffice";
import ContentFavorite from "./ContentFavorite";
import ContentNotice from "./ContentNotice";
import ContentApproval from "./ContentApproval";
import ContentMail from "./ContentMail";

export default {
  name: "MainContent",
  components: {
    ContentWork,
    ContentOffice,
    ContentFavorite,
    ContentNotice,
    ContentApproval,
    ContentMail,
  },
  data() {
    return {
      menuData: [],
    };
  },
};
</script>

<style scoped>

</style>
