import axios from "axios";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES, i18n } from "@/plugins/i18n";

const Trans = {
  get defaultLanguage() {
    return DEFAULT_LANGUAGE;
  },
  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  },
  get currentLanguage() {
    return i18n.locale;
  },
  set currentLanguage(lang) {
    i18n.locale = lang;
  },
  /**
   * 사용자의 언어와 일치하는 첫 번째 지원 언어 가져오기
   * @return {String}
   */
  getUserSupportedLang() {
    const userPreferredLang = Trans.getUserLang();

    // 사용자 기본 브라우저의 lang값이 지원되는지 여부 확인
    if (Trans.isLangSupported(userPreferredLang.lang)) {
      // console.log('userPreferredLang.lang', userPreferredLang.lang)
      return userPreferredLang.lang;
    }
    // ISO가 포함된 lang 값이 지원되는지 확인
    if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
      // console.log('userPreferredLang.langNoISO', userPreferredLang.langNoISO)
      return userPreferredLang.langNoISO;
    }
    // console.log('Trans.defaultLanguage', Trans.defaultLanguage)
    return Trans.defaultLanguage;
  },
  /**
   * 사용자 기본 언어 반환
   */
  getUserLang() {
    const lang =
      window.navigator.language || window.navigator.userLanguage || Trans.defaultLanguage;
    return {
      lang: lang,
      langNoISO: lang.split("-")[0],
    };
  },
  /**
   * 언어를 기타 서비스(axios, html 태그 등)에 설정
   * @param {String} lang
   * @return {String} lang
   */
  setI18nLanguageInServices(lang) {
    Trans.currentLanguage = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
  },
  /**
   * 언어 변경
   * @param lang
   * @return {Promise<any>}
   */
  changeLanguage(lang) {
    if (!Trans.isLangSupported(lang)) return Promise.reject(new Error("Language not supported"));
    if (i18n.locale === lang) return Promise.resolve(lang); // has been loaded prior
    return Trans.loadLanguageFile(lang).then((msgs) => {
      i18n.setLocaleMessage(lang, msgs.default || msgs);
      localStorage.setItem("locale", lang);
      return Trans.setI18nLanguageInServices(lang);
    });
  },
  /**
   * 비동기식으로 언어 파일을 로드함
   * @param lang
   * @return {Promise<*>|*}
   */
  loadLanguageFile(lang) {
    return import(
      /* webpackChunkName: "lang-[request]" */ `@/translations/locales/${lang}/index.js`
    );
  },
  /**
   * 요청된 lang이 지원되는지 확인
   * @param {String} lang
   * @return {boolean}
   */
  isLangSupported(lang) {
    return Trans.supportedLanguages.indexOf(lang) > -1;
  },
};

export { Trans };
