<!--<Select
          :selectValue.sync="select"
          :options="[
            { value: 'a', name: '가' },
            { value: 'b', name: '나' },
            { value: 'c', name: '다' },
          ]"
        >
        </Select>-->

<template>
  <span class="select-box" :style="{ minWidth: minWidth }">
    <select
      :id="id"
      v-model="selectValueSync"
      :class="['select-select', selectClass]"
      :disabled="isDisabled"
      :required="isRequired"
      :multiple="multiple"
      :size="size"
      @change="onChange"
    >
      <template v-for="option in options">
        <option :key="option.value" :value="option.value" :disabled="option.disabled">
          {{ option.name }}
        </option>
      </template>
    </select>
    <span class="select-arrow">
      <!-- <SvgIcon :color="'transparent'" :stroke="'#222222'" :size="22" :viewBox="'0 0 22 13'">
        <ArrowDown />
      </SvgIcon> -->
    </span>
  </span>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon.vue";
import ArrowDown from "@/assets/svg/ArrowDown.vue";

export default {
  name: "Select",
  components: {
    SvgIcon,
    ArrowDown,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectValue: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: null,
    },
    minWidth: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectValueSync: {
      get() {
        return this.selectValue;
      },
      set(value) {
        this.$emit("update:selectValue", value);
      },
    },
  },
  created() {},
  methods: {
    onChange(e) {
      const oldVal = this.selectValueSync;
      const newVal = this.value;
      this.$emit("onChange", newVal, oldVal, e);
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-select {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 22px 10px 12px;
    border : {
      width: 1px;
      style: solid;
      color: #e5e5e5;
      radius: 4px;
    }
    background : {
      image: url(~@/assets/svg/arrowDown.svg);
      position: calc(100% - 12px) 50%;
      repeat: no-repeat;
      size: 8px auto;
    }
  }
  // &-arrow {
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 40px;
  //   height: 40px;
  //   pointer-events: none;
  // }
}
</style>
