<template>
  <div class="doc-footer" role="contentinfo">
    <h2 class="screen_out">{{ $t("message.footer_info") }}</h2>
    <div class="info_corp">
      <span v-if="isAdmin" class="txt_admin">{{ $t("message.intra_admin") }}</span>
    </div>
    <small class="info_copy">© 2022, Helinox. All Rights Reserved.</small>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Layer from "@/components/common/layer/Layer.vue";

export default {
  name: "AppFooter",
  components: {
    Layer,
  },
  data() {
    return {
      isTest: false,
      testHtml: null,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
};
</script>
