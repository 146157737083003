var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_form"},[_c('div',{directives:[{name:"docClick",rawName:"v-docClick",value:(_vm.getEvtTargetBlur),expression:"getEvtTargetBlur"},{name:"docFocusIn",rawName:"v-docFocusIn",value:(_vm.getEvtTargetBlur),expression:"getEvtTargetBlur"}],ref:"optComm",staticClass:"select_comm",class:[
      {
        opt_open: _vm.isOpen && !_vm.disabled,
        opt_hide: _vm.isHide,
      },
    ],attrs:{"aria-disabled":_vm.disabled}},[(_vm.optDesc)?_c('strong',{staticClass:"screen_out"},[_vm._v(_vm._s(_vm.optDesc))]):_vm._e(),_c('a',{ref:"linkOpt",staticClass:"link_selected",class:[{ disabled: _vm.disabled, error: _vm.error }],attrs:{"id":_vm.id,"href":"#none","aria-expanded":_vm.isOpen.toString()},on:{"click":(e) => {
          _vm.setToggle('toggle');
          e.preventDefault();
        },"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.getKeyFocus('first', 0)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.setToggle(false)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.setToggle(false)}]}},[_c('span',{staticClass:"ico_comm ico_select"},[_vm._v("선택됨")]),_vm._v(" "+_vm._s(_vm._f("unescape")(_vm.displaySelName))+" ")]),_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.curValue}}),(_vm.options && _vm.options.length > 0)?_c('div',{staticClass:"box_opt",on:{"keydown":_vm.getEvtFocusScrollStop}},[_c('ul',{staticClass:"list_opt",attrs:{"role":"listbox"}},_vm._l((_vm.options),function(option,index){return _c('li',{key:`${option[_vm.nameField]}${index}${option[_vm.valueField]}`,class:{ on: _vm.isSelected(option) },attrs:{"role":"option","title":option[_vm.nameField]}},[_c('a',{ref:"linkOption",refInFor:true,staticClass:"link_opt",class:{ on: _vm.isSelected(option) },attrs:{"href":"#none","aria-selected":_vm.isSelected(option).toString()},on:{"click":function($event){$event.preventDefault();return ((e) => {
                _vm.setOption(option, index);
                e.preventDefault();
              }).apply(null, arguments)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.getKeyFocus('up', index)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.getKeyFocus('down', index)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.getKeyFocus('exit')}]}},[_c('span',{staticClass:"txt_opt"},[_vm._v(" "+_vm._s(_vm._f("unescape")(option[_vm.nameField]))),(_vm.subNameField)?_c('span',{staticClass:"txt_add"},[_vm._v(_vm._s(_vm._f("unescape")(option[_vm.subNameField])))]):_vm._e()]),(_vm.isSelected(option))?_c('span',{staticClass:"screen_out"},[_vm._v("선택됨")]):_vm._e()])])}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }