import Vue from "vue";
import _ from "lodash";
import qs from "qs";
import moment from "moment-business-days";
import isMobile from "ismobilejs";
import VCalendar from "v-calendar";

import VueAwesomeSwiper from "vue-awesome-swiper";

import { i18n } from "./plugins/i18n";
import docEvent from "@/plugins/docEvent";
import { Plugin } from "vue-fragment";

import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filter from "./filter";

import "./api/axiosInerceptor";

import Api from "@/api";
import apiPath from "@/api/apiPath";

Vue.prototype.$isLocal = process.env.NODE_ENV === "local";
Vue.prototype.$isDKTDevlop = process.env.NODE_ENV === "development_dkt";
Vue.prototype.$isDevlop = process.env.NODE_ENV === "development";
Vue.prototype.$isProd = process.env.NODE_ENV === "production";

Vue.prototype._ = _;
Vue.prototype.moment = moment;
Vue.prototype.qs = qs;
Vue.prototype.$isMobile = isMobile();
Vue.prototype.$Api = Api;
Vue.prototype.$apiPath = apiPath;

Vue.use(VueAwesomeSwiper);

Vue.use(VCalendar, {
  attributes: [
    {
      highlight: true,
      dates: [],
    },
  ],
  locales: {
    ko: {
      masks: {
        title: "YYYY.MM",
      },
    },
  },
});

Vue.use(Plugin);
Vue.use(docEvent);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === "production" ? false : true;

window.addEventListener("message", function (e) {
  if (e.data.code === "ok") {
    window.location.reload();
  }
});

new Vue({
  i18n,
  router,
  store,
  filter,
  render: (h) => h(App),
}).$mount("#app");
