<template>
  <div class="doc-main" role="main">
    <!-- [개발] content-aside 가 접혀진 경우 doc-full 클래스 추가 필요 -->
    <div class="main-content">
      <main-header></main-header>
      <main-content></main-content>
      <main-aside></main-aside>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MainContent from "./main/content/MainContent";
import MainHeader from "./main/header/MainHeader";
import MainAside from "./main/aside/MainAside";

export default {
  name: "Main",
  components: {
    MainAside,
    MainHeader,
    MainContent,
  },
  created() {
    this.getLanguageList();
  },
  methods: {
    ...mapActions(["getLanguageList"]),
  },
};
</script>
