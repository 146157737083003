<!-- fill, stroke, stroke-width 는 css 로 처리 한다  -->
<!-- size 는 css 변수 font-size로 처리 -->
<template>
  <svg
    class="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    :viewBox="computedViewBox"
    :aria-labelledby="id"
  >
    <title :id="id">{{ name }} 아이콘</title>
    <g ref="svgWrap" shape-rendering="geometricPrecision">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },

    viewBox: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      calcWidth: 0,
      calcHeight: 0,
    };
  },
  computed: {
    computedViewBox() {
      let ret = "";
      if (this.viewBox) {
        ret = this.viewBox;
      } else {
        ret = `0 0 ${this.calcWidth} ${this.calcHeight}`;
      }
      return ret;
    },
  },
  mounted() {
    this.getSvgRect();
  },
  methods: {
    getSvgRect() {
      const { width, height } = this.$refs.svgWrap.getBoundingClientRect();
      this.calcWidth = _.ceil(width);
      this.calcHeight = _.ceil(height);
    },
  },
};
</script>
