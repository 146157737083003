<template>
  <div class="item_paging">
    <button
      :disabled="!(current > rangeSize)"
      class="btn_prev"
      type="button"
      @click="switchingPage(start - 1)"
    >
      >
    </button>
    <template v-for="p in rangeSize">
      <a
        v-if="start + p - 1 <= end"
        :key="`page-${p}`"
        :aria-current="start + p - 1 === current ? 'page' : 'false'"
        :class="[start + p - 1 === current ? 'active' : '']"
        class="link_page"
        href="javascript:;"
        @click="switchingPage(start + p - 1)"
      >
        {{ start + p - 1 }}
      </a>
    </template>
    <button
      :disabled="!(start + rangeSize <= final)"
      class="btn_next"
      type="button"
      @click="switchingPage(start + rangeSize)"
    >
      >
    </button>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pagination",
  components: {},
  props: {
    /**
     * current 현재위치
     * @prop {Number} type
     * @default 1
     */
    current: {
      type: Number,
      default: 1,
    },
    /**
     * pageSize 페이지 행 노출 수
     * @prop {Number} type
     * @default 0
     */
    pageSize: {
      type: Number,
      default: 10,
    },
    /**
     * rangeSize 페이징 그룹 크기
     * @prop {Number} type
     * @default 0
     */
    rangeSize: {
      type: Number,
      default: 10,
    },
    /**
     * pageCount 페이지 최대 수
     * @prop {Number} type
     * @default 0
     */
    pageCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      final: 0,
      start: 0,
      end: 0,
    };
  },
  computed: {
    detect() {
      return [this.current, this.pageSize, this.rangeSize, this.pageCount];
    },
  },
  watch: {
    detect: {
      immediate: false,
      handler() {
        this.initPage();
      },
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    /**
     * switchingPage
     * 페이지 전환
     * @param page 페이지
     */
    switchingPage(page) {
      this.$emit("select", page);
    },
    initPage() {
      const finalPage = Math.ceil(this.pageCount / this.pageSize); // 마지막 페이지
      const currentRange = Math.floor((this.current - 1) / this.rangeSize + 1); // 내림
      const startPage = this.pageCount > 0 ? (currentRange - 1) * this.rangeSize + 1 : 1;
      const endPage =
        this.pageCount > 0
          ? startPage + this.rangeSize - 1 > finalPage
            ? finalPage
            : startPage + this.rangeSize - 1
          : 1;
      this.start = startPage;
      this.end = endPage;
      this.final = finalPage;
    },
  },
};
</script>
