import Vue from "vue";
import _ from "lodash";

Vue.filter("currency", (val) => {
  if (!val) {
    return "0";
  }
  return new Number(val).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
});

Vue.filter("convertMinToHour", (val) => {
  if (!val) {
    return "0";
  }
  return parseInt(val) / 60;
});

Vue.filter("convertMenuType", (val) => {
  if (!val) {
    return "";
  }
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
});

/**
 * unescape
 */
Vue.filter("unescape", (value) => {
  if (!value) return "";
  return _.unescape(value);
});
