<template>
  <div class="box_category" :class="{ edit_box: isEdit }">
    <div class="box_head">
      <h3 class="tit_box">{{ type | convertMenuType }}</h3>
    </div>
    <div v-if="isFavorite && !isShowInput && !list.length" class="box_cont none_cont">
      <p class="txt_none" v-html="$t('message.content_D_1')"></p>
    </div>
    <div v-if="!(isFavorite && !isShowInput && !list.length)" class="box_cont">
      <ul class="list-group">
        <li v-if="isEdit && isShowInput" class="list-group-item">
          <div class="item_add">
            <span class="wrap_inp">
              <span class="item_inp_type1" :class="{ error_inp: isErrorTitle }">
                <input
                  v-model="newTitle"
                  type="text"
                  :placeholder="$t('message.content_L_1')"
                  class="inp_comm"
                  title="표기문구 입력"
                  maxlength="17"
                />
              </span>
            </span>
            <span class="wrap_inp">
              <span class="item_inp_type1" :class="{ error_inp: isErrorTitleSub }">
                <input
                  v-model="newUrl"
                  type="text"
                  :placeholder="$t('message.banner_L_4')"
                  class="inp_comm"
                  title="인터넷주소 입력"
                />
              </span>
            </span>
            <p v-show="isErrorTitle || isErrorTitleSub" class="txt_error">{{ errorText }}</p>
            <button class="btn_cate btn_confirm" @click="addMenu">
              <span class="ico_comm ico_confirm">{{ $t("message.btn_confirm") }}</span>
            </button>
          </div>
        </li>
        <draggable :list="list" :disabled="!isEdit" :move="checkMove" class="drag-group">
          <section v-for="(item, index) in list" :key="index">
            <li v-if="!item.deleteFlag" class="list-group-item">
              <edit-input
                :item="item"
                :type="type"
                :is-edit="isEdit"
                :is-favorite="isFavorite"
                :is-edit-menu="menuPriorityId == item.priority ? true : false"
                @initedit="editMenu"
                @openeditmenu="openEditMenu"
                @removeMenu="removeMenu"
              ></edit-input>
            </li>
          </section>
        </draggable>
      </ul>
    </div>
    <div v-if="!isFavorite && !isAdmin" class="group_btn">
      <button v-if="!isEdit" type="button" class="btn_g" @click="showSort">
        {{ $t("message.btn_edit") }}
      </button>
      <button v-if="isEdit" type="button" class="btn_g btn_g2" @click="modifyMenuConfirm">
        {{ $t("message.btn_complete") }}
      </button>
    </div>
    <div v-if="isFavorite || isAdmin" class="group_btn">
      <button v-if="!isEdit" type="button" class="btn_g" @click="showSort">
        {{ $t("message.btn_edit") }}
      </button>
      <button
        v-if="isEdit && !isShowInput"
        type="button"
        class="btn_fav btn_add"
        @click="addInputFavorite(true, true)"
      >
        <span class="ico_comm"></span>
        {{ $t("message.btn_add") }}
      </button>
      <button
        v-if="isEdit && !isShowInput"
        type="button"
        class="btn_fav btn_finish"
        @click="modifyMenuConfirm"
      >
        {{ $t("message.btn_complete") }}
      </button>
      <button
        v-if="isEdit && isShowInput"
        type="button"
        class="btn_g btn-cancel"
        @click="addInputFavorite(true, false)"
      >
        <SvgIcon
          class="svg svg--type-cancel"
          :viewBox="'0 0 32 32'"
          :name="`${$t('message.btn_cancel')}}`"
        >
          <IconClose />
        </SvgIcon>
        <!-- 1111 <span class="ico_comm ico_del"></span>{{ $t("message.btn_write") }}
        {{ $t("message.btn_cancel") }} -->
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import EditInput from "./EditInput";

import { EventBus } from "../../../plugins/eventBus";
import Validate from "../../../plugins/Validate";
import { mapActions } from "vuex";

import SvgIcon from "@/components/common/SvgIcon.vue";
import { IconEdit, IconComplete, IconAdd, IconClose } from "@/assets/svg/";

export default {
  name: "MenuList",
  components: {
    draggable,
    EditInput,
    SvgIcon,
    IconEdit,
    IconComplete,
    IconAdd,
    IconClose,
  },
  props: ["type", "list"],
  display: "Simple",
  order: 0,
  data() {
    return {
      isEdit: false, // 편집버튼
      isShowInput: false, // 메뉴추가 input 활성화
      isFavorite: false, // 즐겨찾기여부
      isErrorTitle: false, // 타이틀 애러노출여부
      isErrorTitleSub: false, // 서브타이틀 애러노출여부
      errorText: "", // 애러문구
      menuPriorityId: null, // 메뉴 순번
      newTitle: "", // 신규 추가 타이틀
      newUrl: "", // 신규 추가 Url
      emptyFavorite: false, // 즐겨찾기 목록 존재여부
      isDoubleCheck: false, // 버튼 더블클릭 flag
    };
  },
  mounted() {
    EventBus.$on("save:menu", this.modifyMenu);
    this.isFavorite = this.type === "FAVORITE" ? true : false;
    this.emptyFavorite = this.isFavorite && !this.isShowInput && !this.list.length;
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    ...mapActions(["getMenuWork", "getMenuOffice", "getMenuFavorite"]),
    showSort() {
      this.isEdit = !this.isEdit;
      this.isShowInput = false;
      this.menuPriorityId = null;
    },
    modifyMenuConfirm() {
      if (!this.isDoubleCheck) {
        EventBus.$emit("open:confirm", {
          message: this.$t("message.change_confirm"),
          name: "menu",
          type: this.type,
        });
      }
      // else {
      //     EventBus.$emit("open:alert", {message: "저장중입니다."});
      // }
    },
    modifyMenu(type) {
      if (this.type === type) {
        this.isDoubleCheck = true;
        let tempMenuList = [];
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].priority = i + 1;
          tempMenuList.push(this.list[i]);
        }

        const sendData = tempMenuList.map((el) => {
          let result = { ...el };
          delete result.updatedAt;
          delete result.createdAt;
          return result;
        });

        // Api
        let response = null;
        if (this.type === "FAVORITE") {
          response = this.$Api.modifyFavorite(sendData);
        } else {
          response = this.$Api.modifyMenu(this.type, { params: sendData, isAdmin: this.isAdmin });
        }

        response
          .then(() => {
            if (this.type === "WORK") {
              this.getMenuWork("WORK");
            } else if (this.type === "OFFICE") {
              this.getMenuOffice("OFFICE");
            } else if (this.type === "FAVORITE") {
              this.getMenuFavorite();
            }
            this.isEdit = !this.isEdit;
            this.isShowInput = false;
            this.menuPriorityId = null;
            EventBus.$emit("open:toast", {
              popup: false,
              message: this.$t("message.menu_edit_confirm"),
            });
            this.isDoubleCheck = false;
          })
          .catch((e) => {
            this.isDoubleCheck = false;
            console.log(e.message);
          });
      }
    },
    addInputFavorite(isEdit, isShowInput) {
      if (!this.isDoubleCheck) {
        this.newTitle = "";
        this.newUrl = "";
        this.isErrorTitle = false;
        this.isErrorTitleSub = false;
        this.errorText = "";
        this.isEdit = isEdit;
        this.isShowInput = isShowInput;
        this.menuPriorityId = null;
      }
    },
    addMenu() {
      if (!this.isDoubleCheck) {
        if (!this.newTitle.trim() && !this.newUrl.trim()) {
          this.isErrorTitle = true;
          this.isErrorTitleSub = true;
          this.errorText = this.$t("message.content_validation");
        } else {
          if (!this.newTitle.trim()) {
            this.isErrorTitle = true;
            if (!Validate.urlPattern(this.newUrl)) {
              this.isErrorTitleSub = true;
              this.errorText = this.$t("message.content_notation_url_validation");
            } else {
              this.errorText = this.$t("message.content_notation_validation");
            }
          } else {
            this.isErrorTitle = false;
          }
          if (!this.newUrl.trim()) {
            this.isErrorTitleSub = true;
            this.errorText = this.$t("message.content_address_validation");
          } else {
            if (this.newTitle.trim() && !Validate.urlPattern(this.newUrl)) {
              this.isErrorTitle = false;
              this.isErrorTitleSub = true;
              this.errorText = this.$t("message.content_url_validation");
            } else {
              this.isErrorTitleSub = false;
            }
          }
        }

        if (!this.newTitle.trim() || !this.newUrl.trim() || !Validate.urlPattern(this.newUrl)) {
          return false;
        } else {
          this.errorText = "";
        }

        let tempNewMenu = {
          type: this.type,
          name: this.newTitle,
          url: this.newUrl,
          open: true,
          deleteFlag: false,
          priority: this.list.length + 1,
          // priority: this.list.length > 0 ? this.list.slice(0).sort(function (a, b) {
          //     return a.priority < b.priority
          // })[this.list.length - 1].priority + 1 : 1
        };
        this.list.push(tempNewMenu);

        this.newTitle = "";
        this.newUrl = "";
        this.addInputFavorite(true, false);
      }
    },
    openEditMenu(id) {
      this.isShowInput = false;
      this.menuPriorityId = id;
    },
    editMenu(data) {
      if (!this.isDoubleCheck) {
        this.list.map((el) => {
          if (el.priority == data.priority) {
            el.name = data.name;
            el.url = data.url;
            return el;
          }
        });

        this.menuPriorityId = null;
      }
    },
    removeMenu(data) {
      if (!this.isDoubleCheck) {
        this.list.map((el) => {
          if (el.priority === data.priority) {
            el.deleteFlag = true;
            return el;
          }
        });
      }
    },
    checkMove() {
      if (this.isDoubleCheck) {
        return false;
      }
    },
  },
};
</script>
