const isProd = process.env.NODE_ENV === "production";
const en = {
  message: {
    companyName: "Helinox",
    test: "en",
    intranet: "Communication",
    btn_login: "Login",
    id_save: "Save ID",
    password_reset: "Reset password",
    forget_password: "If you forgot your password,try ",
    text_try: "",
    btn_edit: "Edit",
    btn_add: "Add",
    btn_complete: "Complete",
    btn_write: "Write",
    btn_cancel: "Cancel",
    content_D_1: "Favorite link does not exist.<br> Please add a link to your favourites!",
    header_D_1: "Thanks for being with us today, too!",
    btn_password_change: "Change password",
    btn_logout: "Log Out",
    logout_message: "Are you sure log out?",
    banner_setting: "Billboard Setting",
    banner_D_1:
      "When you chose billboards, you can change the order and edit them. You can check the content of the billboard to register through the preview.",
    banner_D_2:
      "You can register up to 5 billboard notices. You can decide whether to apply them with the toggle button below.",
    banner_D_3: "Enter the title.",
    banner_D_4: "Enter the subtitle.",
    banner_D_5: "Register the billboard.",
    label_choice: "Select",
    banner_L_1: "Billboard Text",
    label_required: "Required",
    banner_L_2: "Enter the title.",
    banner_L_3: "Enter the subtitle.",
    banner_L_4: "Enter the internet address (https://)",
    banner_L_5: "Applied Link",
    btn_temp: "Save temporarily",
    holiday_L_1: "Major Schedule",
    holiday_D_1: "There is no schedule for this month.",
    btn_correction: "Edit",
    btn_delete: "Delete",
    content_L_1: "Text to show (within 17 characters)",
    btn_confirm: "OK",
    content_D_2: "Enter the text and address.",
    content_D_3: "Check the text and URL format.",
    content_D_4: "Check the text.",
    content_D_5: "Check the address.",
    content_D_6: "Check the URL format.",
    banner_image: "Upload banner image",
    change_confirm: "Are you sure you want to save your changes?",
    upload_image: "You can register JPG, JPEG, PNG, and GIF files only.",
    upload_size: "30 MB or less",
    btn_file_delete: "Delete file",
    temp_save_confirm: "Unsaved content will not be applied.\nDo you want to proceed?",
    banner_text_confirm: "Check the text on the billboard.",
    url_confirm: "Ccheck the URL format.",
    image_confirm: "Save the image.",
    temp_save_complete: "Content has been saved.",
    banner_setting_complete: "Are you sure you want to end the billboard setting?",
    banner_setting_complete_confirm: "Billboard setting is complete.",
    btn_preview: "Preview",
    common_error: "There is a problem with the server. Contact the manager.",
    pw_validation:
      "Only uppercase and lowercase English characters, numbers, and special characters are allowed.",
    login_validation: "Enter your ID/Password",
    login_id_validation: "Enter your ID",
    login_pw_validation: "Enter password",
    admin_validation: "The account does not have administrator's authority.",
    btn_close: "Close",
    content_validation: "Enter the text and address.",
    content_notation_url_validation: "Check the text and URL format.",
    content_notation_validation: "Check the text.",
    content_address_validation: "Check the address",
    content_url_validation: "Check the URL format.",
    menu_edit_confirm: "Editing is complete.",
    approval: "Electronic Approval",
    draft: "Submit",
    apprBefore: "To Approve",
    rejectCount: "Reject",
    document: "Document",
    footer_info: "Service Use Information",
    intra_admin: "Communication Manager",
    email: "E-mail",
    messenger: "Messenger Downloads",
    erp: "Download Duzon ERPIU",
    company_select: "Move to Company",
    select_company: "Select company",
    company_L_1: "Please select a company",
    btn_move: "Move",
    mail_empty: "There are no mails.",
    holiday_T_1: "today schedule",
    mail: "Mail",
    highTemperature: "{temp}°C Higher",
    lowTemperature: "{temp}°C Lower",
    concurrent: "Additional Post",
    init_google_auth: "Initialize Google Account",
    more: "More",
    notice: "Notice",
    notice_empty: "There are no notices.",
    apprBeforeDoc: "To Approve",
    apprBeforeDoc_empty: "There are no documents to approve.",
    receiveMail: "Mail",
    receiveMail_empty: "There are no mails.",
  },
  date: {
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
  },
};

export default en;
