<template>
  <box-category :is-empty-data="approvalList.length === 0">
    <template #head>
      <h3 class="tit_box">{{ $t("message.apprBeforeDoc") }}</h3>
      <button type="button" class="btn_refresh" @click="getApprovalList">
        <span class="ico_comm ico_refresh">{{ $t("message.refresh") }}</span>
      </button>
    </template>
    <template #cont>
      <ul class="list-group">
        <li v-for="(item, idx) in approvalList" :key="idx" class="list-group-item">
          <span class="item_txt">
            <a class="txt_item" href="javascript:;" @click.prevent="openApprovalDetail(item)">
              {{ item.docSubject }}
            </a>
          </span>
        </li>
      </ul>
    </template>
    <template #contEmpty>
      <p class="txt_none">{{ $t("message.apprBeforeDoc_empty") }}</p>
    </template>
    <template #side>
      <button type="button" class="btn_g" @click="goToApprovalSite">
        {{ $t("message.more") }}
      </button>
    </template>
  </box-category>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoxCategory from "@/components/main/content/BoxCategory";
import { openApprovalSite } from "@/utils/common";

export default {
  name: "ContentApproval",
  components: { BoxCategory },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["approvalList"]),
  },
  mounted() {
    this.getApprovalList();
  },
  methods: {
    openApprovalSite,
    ...mapActions(["getApprovalList"]),
    /**
     * link: 결재 상세
     * @param mailId
     */
    openApprovalDetail(item) {
      this.openApprovalSite(`/doc/ab/${item.docId}/${item.apprLineId}`);
    },
    /**
     * link: 전자결재 결재할 문서함으로 이동
     */
    goToApprovalSite() {
      this.openApprovalSite("/box/ab");
    },
  },
};
</script>
