<template>
  <article class="layer-article">
    <header v-if="title" :class="[`layer-header ${headerClass}`]">
      <h1 class="layer-header__title">{{ title }}</h1>
    </header>
    <div :class="[`layer-body ${bodyClass}`]">
      <slot />
    </div>
    <button class="layer-btn__close" @click="onClickLayerClose">
      <SvgIcon class="svg--type-close" :viewBox="'0 0 32 32'"><IconClose /></SvgIcon>
    </button>
  </article>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import { IconClose } from "@/assets/svg";
export default {
  name: "Layer",
  components: {
    SvgIcon,
    IconClose,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    headerClass: { type: String, default: "" },
    bodyClass: { type: String, default: "" },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    onClickLayerClose() {
      this.$emit("onClickLayerClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.layer {
  &-article {
    position: absolute;
    padding: 40px 70px 40px 40px;
    border-radius: 2px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
  }
  &-header {
    &__title {
      color: #222222;
      font-size: 15px;
    }
    & + .layer-body {
      padding-top: 16px;
    }
  }
  &-body {
  }
  &-btn {
    &__close {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
.svg {
  &--type {
    &-close {
      --svg-width: 32px;
      --svg-fiil: #000000;
    }
  }
}
</style>
