<template>
  <fragment>
    <div class="direct-link">
      <a href="#mainContent">본문 바로가기</a>
    </div>
    <div class="container-doc">
      <AppHeader />
      <AppMain />
      <AppFooter />
    </div>
    <BannerPopup v-if="isAdmin" />
    <Confirm />
    <Alert />
    <Toast />
    <DocumentLayer
      v-if="showDocumentLayerAdmin"
      @openLayerUser="openLayerUser"
      @closeDocumentLayer="closeDocumentLayer"
    />
    <DocumentLayerUser
      v-if="showDocumentLayer"
      @openLayerAdmin="openLayerAdmin"
      @closeDocumentLayer="closeDocumentLayer"
    />
    <DocumentAlert />
  </fragment>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppMain from "@/components/AppMain.vue";
import AppFooter from "@/components/AppFooter.vue";
import BannerPopup from "@/components/banner/Banner.vue";
import Confirm from "@/components/common/Confirm.vue";
import Alert from "@/components/common/Alert.vue";
import Toast from "@/components/common/Toast.vue";
import DocumentLayer from "@/views/DocumentLayer";
import DocumentLayerUser from "@/views/DocumentLayer_USER";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../plugins/eventBus";
import DocumentAlert from "@/views/DocumentAlert";
export default {
  name: "Home",
  components: {
    DocumentAlert,
    Toast,
    AppFooter,
    AppMain,
    AppHeader,
    BannerPopup,
    Confirm,
    Alert,
    DocumentLayer,
    DocumentLayerUser,
  },
  data() {
    return {
      showDocumentLayer: false,
      showDocumentLayerAdmin: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  created() {
    EventBus.$on("open:document", this.isShowDocumentLayer);
    this.getData();
  },
  methods: {
    ...mapActions([
      "getUser",
      "getBannerDisplay",
      "getWeather",
      "getMenuWork",
      "getMenuOffice",
      "getMenuFavorite",
    ]),
    async getData() {
      try {
        await Promise.all([
          this.getUser(),
          this.getBannerDisplay(),
          this.getWeather(),
          this.getMenuWork("WORK"),
          this.getMenuOffice("OFFICE"),
          this.getMenuFavorite("FAVORITE"),
        ]);
      } catch (e) {
        console.log(e);
      }
    },
    isShowDocumentLayer() {
      if (this.isAdmin) {
        this.showDocumentLayerAdmin = !this.showDocumentLayerAdmin;
      } else {
        this.showDocumentLayer = !this.showDocumentLayer;
      }
    },
    openLayerUser() {
      this.showDocumentLayerAdmin = false;
      this.showDocumentLayer = true;
    },
    openLayerAdmin() {
      this.showDocumentLayerAdmin = true;
      this.showDocumentLayer = false;
    },
    closeDocumentLayer() {
      this.showDocumentLayerAdmin = false;
      this.showDocumentLayer = false;
    },
  },
};
</script>
