<!--
<Radio :name="'name'" :selectValue.sync="select" :value="radioValue1"> 111 </Radio>
-->

<template>
  <span :class="['checkbox-box', checkboxClass]">
    <input
      :id="computedId"
      v-model="selectValueSync"
      type="checkbox"
      :class="['checkbox-input']"
      :name="name"
      :value="value"
      :disabled="isDisabled"
      :required="isRequired"
      @change="onChange"
    />
    <label :for="computedId" class="checkbox-label">
      <slot />
    </label>
  </span>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    selectValue: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      //:radioClass="'test b1'"
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectValueSync: {
      get() {
        return this.selectValue;
      },
      set(value) {
        this.$emit("update:selectValue", value);
      },
    },
    computedId() {
      let ret = null;
      if (this.id) {
        ret = this.id;
      } else {
        ret = this.value;
      }
      return ret;
    },
  },
  created() {},
  methods: {
    onChange(e) {
      const value = this.selectValueSync;
      this.$emit("onChange", e, value);
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 16px;

.checkbox {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-input {
    appearance: none;
    position: relative;
    width: $size;
    height: $size;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      box-sizing: border-box;
      width: $size;
      height: $size;
      border : {
        width: 1px;
        style: solid;
        color: #d9d9d9;
        radius: 2px;
      }
      background-color: #ffffff;
    }
    &::after {
      left: 50%;
      top: 50%;
      width: 11px;
      height: 8px;
      background {
        color: transparent;
      }
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::before {
        border-color: var(--sm-pink);
      }
    }
    &:checked {
      &::before {
        background-color: var(--sm-pink);
        border-color: var(--sm-pink);
      }
      &::after {
        background: {
          image: url(~@/assets/svg/iconCheck.svg);
          position: 0 0;
          repeat: no-repeat;
          size: 11px 8px;
        }
      }
    }
    &:disabled {
      &::before {
        border-color: #e1e1e1;
        background-color: #f5f5f5;
      }
      &::after {
        filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(106%) contrast(85%);
      }
    }
  }
  &-label {
    display: inline-block;
    padding: 0 8px;
    color: #777777;
    font : {
      size: 13px;
      weight: 500;
    }
    vertical-align: middle;
  }
}
</style>
