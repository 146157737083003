<template>
  <box-category :is-empty-data="noticeList.length === 0">
    <template #head>
      <h3 class="tit_box">{{ $t("message.notice") }}</h3>
      <button type="button" class="btn_refresh" @click="getNoticeListData">
        <span class="ico_comm ico_refresh">{{ $t("message.refresh") }}</span>
      </button>
    </template>
    <template #cont>
      <ul class="list-group">
        <li v-for="(item, idx) in noticeList" :key="idx" class="list-group-item">
          <a class="item_txt" href="javascript:;" @click.prevent="openNoticeDetail(item)">
            <span class="txt_item">{{ item.title }}</span>
          </a>
        </li>
      </ul>
    </template>
    <template #contEmpty>
      <p class="txt_none">{{ $t("message.notice_empty") }}</p>
    </template>
    <template #side>
      <button type="button" class="btn_g" @click="goToBbsSite">
        {{ $t("message.more") }}
      </button>
    </template>
  </box-category>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoxCategory from "@/components/main/content/BoxCategory";
import { getBoardId, openBbsSite } from "@/utils/common";

export default {
  name: "ContentNotice",
  components: { BoxCategory },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo", "noticeList"]),
  },
  watch: {
    "userInfo.companyId": {
      immediate: true,
      handler(companyId) {
        if (companyId) this.getNoticeListData();
      },
    },
  },
  methods: {
    openBbsSite,
    getBoardId,
    ...mapActions(["getNoticeList"]),
    /**
     * link: 공지사항 상세
     */
    openNoticeDetail(item) {
      this.openBbsSite(`/bbs/post/${item.boardId}/${item.postId}`);
    },
    /**
     * link: 공지사항으로 이동
     */
    goToBbsSite() {
      this.openBbsSite("/");
    },
    /**
     * get: 공지사항 리스트
     */
    getNoticeListData() {
      const boardId = this.getBoardId();
      this.getNoticeList({ boardId });
    },
  },
};
</script>
