import store from "@/store";
import { Trans } from "@/translations";
import LocalStorageService from "@/plugins/LocalStorageService";

/**
 * 언어 설정
 * @param lang
 * @param _ this
 * @return {Promise<void>}
 */
export const setLanguage = async (lang, _) => {
  await axios.get(_.$apiPath.SET_LOCALE, { params: { locale: lang } }).then((res) => {
    const accessToken = res.data?.result?.accessToken;
    if (res && res.status === 200 && accessToken) {
      LocalStorageService.setToken({
        access_token: accessToken,
        refresh_token: res.data.result.refreshToken,
      });
      checkChangeEmpLang(true);
    } else {
      // 비정상 케이스
      checkChangeEmpLang();
    }
  });
};

/**
 * open link: 전자결재 사이트
 * @param url
 * @return {boolean}
 */
export function openApprovalSite(url) {
  const userInfo = this.$store.getters.userInfo;
  const cmpId = userInfo.companyId;
  const accountId = userInfo.accountId;
  if (!cmpId || !accountId) return false;

  let domain = process.env.VUE_APP_APPROVAL_URL + "/api/login/handoverRedirect?cmpId=";

  window.open(`${domain}${cmpId}&accountId=${accountId}&urlPath=${url}`);
}

/**
 * open link: 게시판 사이트
 * @param url
 * @return {boolean}
 */
export function openBbsSite(url) {
  const userInfo = this.$store.getters.userInfo;
  const cmpId = userInfo.companyId;
  const accountId = userInfo.accountId;
  if (!cmpId || !accountId) return false;

  let domain = process.env.VUE_APP_BBS_URL + "/api/login/handoverRedirect?cmpId=";

  window.open(`${domain}${cmpId}&accountId=${accountId}&urlPath=${url}`);
}

/**
 * get: 법인별 boardId
 */
export function getBoardId() {
  const userInfo = this.$store.getters.userInfo;
  const cmpId = userInfo.companyId;
  const returnData = 0; // 법인 게시판 미연결 시 0번 보냄

  if (!cmpId) return returnData;

  switch (cmpId) {
    case 1: // HINC
      return "31";
    case 2: // HPAC
      return "33";
    case 3: // HEVN
      return "35";
    case 4: // HJPN
      return "37";
    case 5: // HURE
      return "245";
    case 6: // HUTR
      return "246";
    case 1000000: // Helinox Pte. Ltd
      return "261";
  }
  return returnData;
}

/**
 * 토큰에서 lang 추출
 * @returns {string|null} lang
 */
export const getTokenLang = () => {
  if (!LocalStorageService.getAccessToken()) return null;

  const token = LocalStorageService.getDecodeAccessToken();
  return JSON.parse(token.user).lang;
};

/**
 * 언어변경 체크/설정
 * @param reload 리로드 여부
 */
export const checkChangeEmpLang = (reload = false) => {
  const tokenLang = getTokenLang();
  const transLang = Trans.currentLanguage;
  const storageLang = localStorage.getItem("locale");

  if (!tokenLang && storageLang) {
    Trans.changeLanguage(storageLang);
    store.commit("CHANGE_LANGUAGE", storageLang);
    return false;
  }

  if (tokenLang && !storageLang) {
    localStorage.setItem("locale", tokenLang);
  }

  if (transLang !== tokenLang) {
    Trans.changeLanguage(tokenLang);
    store.commit("CHANGE_LANGUAGE", tokenLang);
    if (reload) window.location.reload();
  }
};
