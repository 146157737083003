<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "~flatpickr/dist/flatpickr.css";
@import "../src/assets/css/common.scss";
@import "../src/assets/css/login.scss";
@import "../src/assets/css/custom.css";
@import "../src/assets/css/swiper.min.css";
/* 레이어 위 플랫피커 대응 */
.flatpickr-calendar.__layer-up {
  z-index: 1000 !important;
}
/* 플랫피커 비활성화 대응 */
.flatpickr-days .flatpickr-day:nth-child(7n).prevMonthDay.flatpickr-disabled,
.flatpickr-days .flatpickr-day:nth-child(7n).prevMonthDay.flatpickr-disabled:focus,
.flatpickr-days .flatpickr-day:nth-child(7n).flatpickr-disabled.prevMonthDay:hover,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).prevMonthDay.flatpickr-disabled,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).prevMonthDay.flatpickr-disabled:focus,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).flatpickr-disabled.prevMonthDay:hover,
.flatpickr-days .flatpickr-day:nth-child(7n).nextMonthDay.flatpickr-disabled,
.flatpickr-days .flatpickr-day:nth-child(7n).nextMonthDay.flatpickr-disabled:focus,
.flatpickr-days .flatpickr-day:nth-child(7n).flatpickr-disabled.nextMonthDay:hover,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).nextMonthDay.flatpickr-disabled,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).nextMonthDay.flatpickr-disabled:focus,
.flatpickr-days .flatpickr-day:nth-child(7n + 1).flatpickr-disabled.nextMonthDay:hover {
  color: #b3b3b3;
} /* 2020-11-12 추가 */

/* 레이어 강제 맨앞 */
.comm_layer.forced-front {
  z-index: 99999;
}
</style>
