<template>
  <span
    class="tag_g"
    :class="[isBorder ?
    (type === BADGE_TYPE.WHITE_RED || type === BADGE_TYPE.WHITE_GRAY) ? 'type_none' : null
    : null,
    type]">
    <slot>{{ text }}</slot>
  </span>
</template>
<script>
/*
 * 태그 스타일 가이드
 * tag_g : 초과
 * tag_g type_none : 보더 없는 타입
 * tag_g tag_down : 부족
 * tag_g tag_short : 단축근무
 * tag_g tag_leave : 휴직
 * tag_g tag_position : 겸임
 */
export const BADGE_TYPE = {
  WHITE_RED: '',
  WHITE_GRAY: 'tag_down',
  GRAY_WHITE: 'tag_short',
  YELLOW_BLACK: 'tag_leave',
  LIGHTBLUE_BLUE: 'tag_position'
};

export default {
  name: 'DkBadge',
  props: {
    /**
     * 텍스트
     * @prop {String} text
     * @default ''
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * border 여부
     * @prop {Boolean} isBorder
     * @default true
     */
    isBorder: {
      type: Boolean,
      default: true
    },
    /**
     * 타입
     * @prop {String} type
     * @default BADGE_TYPE.WHITE_RED
     */
    type: {
      type: String,
      default: BADGE_TYPE.WHITE_RED,
      validator: value => Object.keys(BADGE_TYPE).find(key => BADGE_TYPE[key] === value)
    }
  },
  data () {
    return {
      BADGE_TYPE
    };
  }
};
</script>
