import VueI18n from "vue-i18n";
import Vue from "vue";
import locales from "@/translations/locales";

export const DEFAULT_LANGUAGE = "ko";
export const FALLBACK_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = ["en", "ko"];

document.querySelector("html").setAttribute("lang", DEFAULT_LANGUAGE);

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // 로컬언어 설정
  fallbackLocale: FALLBACK_LANGUAGE,
  formatFallbackMessages: false, // 값이 없으면 기본언어값 반영
  messages: locales,
});
