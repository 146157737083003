const apiPath = {
  LOGIN: `/api/login`,
  LOGOUT: `/api/logout`,
  SET_LOGIN_LOCALE: `/api/setLoginLocale`, //auth 체크 안하는거
  LOGIN_COMPANY: `/api/login/company`,
  LOGIN_COMPANY_SELECT: `/api/login/companySelect`,

  MENU: `/api/menu`,
  USER: `/api/user`,
  WEATHER: `/api/weather`,
  BANNER: `/api/banner`,
  BANNER_DISPLAY: `/api/banner/display`,
  FAVORITE: `/api/favorite`,
  SET_LOCALE: `/api/setLocale`,

  INTERNAL_WORKTIME: `/api/internal/workTime`,
  INTERNAL_EVENTHOLIDAY: `/api/internal/eventHoliday`,
  INTERNAL_GET_SELECTPOINT: `/api/internal/getSelectPoint`,
  INTERNAL_GET_USERVACLIST: `/api/internal/getUserVacList`,
  INTERNAL_APPROVAL: `/api/internal/approval`, //전자결제 data

  FILE_UPLOAD: `/api/file/upload`,
  FILE_TEMP_URI: `/api/file/downloadUrl`,
  NOTICE_LIST: `/api/board`,
  NOTICE_DETAIL: `/api/board`,
  DOCUMENT_DOWN: `/api/document/fileDown`,
  DOCUMENT_CHECK: `/api/document/check`,
  DOCUMENT_LIST: `/api/document/history`,
  USER_SERCH: `/api/document/search`,
  ADMIN_CHECK: `/api/document/check`,
  ISSUE_PDF: `/api/document/download`,
  DOCUMENT_HISTROY: `/api/document/history`,
  DOCUMENT_EXCEL: `/api/document/excel`,
  AUTH_GOOGLE: `/api/google/auth`,
  MAIL_GOOGLE: `/api/google/mail`,
  CALENDAR_GOOGLE: `/api/google/calendar`,
  INIT_AUTH_GOOGLE: `/api/google/initAuth`,
  APPROVAL_LIST: `/api/internal/myApprovalList`,
};

export default apiPath;
