import { getBoardId } from "@/utils/common";

const axios = window.axios;
import apiPath from "@/api/apiPath";

export default {
  // 로그인
  /*
    accountId, password
  */
  async login() {
    const result = await axios.get(`${apiPath.LOGIN}`);
    return result;
  },
  // 로그아웃
  async logout(params) {
    const result = await axios.get(`${apiPath.LOGOUT}`, { params: params });
    return result;
  },
  // 법인정보 불러오기
  async getCompanyInfo(params) {
    const result = await axios.get(`${apiPath.LOGIN_COMPANY}`, { params });
    return result;
  },
  // 법인이동
  async companySelect(params) {
    const result = await axios.get(`${apiPath.LOGIN_COMPANY_SELECT}`, {
      params,
    });
    return result;
  },
  // 근무시간 연계, 이달의 일정, 일별 일정 목록
  async getWorkTime() {
    const result = await axios.get(`${apiPath.INTERNAL_WORKTIME}`);
    return result;
  },
  // 근무시간 연계, 이달의 일정, 일별 일정 목록
  async getEventHoliday(params) {
    const result = await axios.get(`${apiPath.INTERNAL_EVENTHOLIDAY}`, { params: params });
    return result;
  },
  // 사원 마일리지 가용현황
  async getSelectPoint() {
    const result = await axios.get(`${apiPath.INTERNAL_GET_SELECTPOINT}`);
    return result;
  },
  // 휴가시스템 API
  async getUserVacList() {
    const result = await axios.get(`${apiPath.INTERNAL_GET_USERVACLIST}`);
    return result;
  },
  // 메뉴 조회
  /*
    menuType
  */
  async getMenu(type) {
    const result = await axios.get(`${apiPath.MENU}/${type}`);
    return result;
  },
  // 메뉴 수정
  /*
    authorityType, id, name, priority, type, updateAt, url
  */
  async modifyMenu(type, { params, isAdmin }) {
    const userType = isAdmin ? "admin" : "user";
    const result = await axios.put(`${apiPath.MENU}/${type}/${userType}`, params);
    return result;
  },
  // 유저정보
  async getUser() {
    const result = await axios.get(`${apiPath.USER}`);
    return result;
  },
  // 날씨 정보
  async getWeather() {
    const result = await axios.get(`${apiPath.WEATHER}`);
    return result;
  },
  // 배너 전체 리스트
  async getBanner() {
    const result = await axios.get(`${apiPath.BANNER}`);
    return result;
  },
  // 배너 리스트 저장
  async modifyBanner(params) {
    const result = await axios.put(`${apiPath.BANNER}`, params);
    return result;
  },
  // 전광판 노출 배너 리스트
  async getBannerDisplay() {
    const result = await axios.get(`${apiPath.BANNER_DISPLAY}`);
    return result;
  },
  // 즐겨찾기 메뉴 조회
  async getFavorite() {
    const result = await axios.get(`${apiPath.FAVORITE}`);
    return result;
  },
  // 즐겨찾기 메뉴 수정
  async modifyFavorite(params) {
    const result = await axios.put(`${apiPath.FAVORITE}`, params);
    return result;
  },

  async getApproval() {
    //전자결제 data get
    const result = await axios.get(`${apiPath.INTERNAL_APPROVAL}`);
    return result;
  },

  async uploadFile(file) {
    const formData = new FormData();
    formData.append("fileList", file);
    const result = await axios.post(`${apiPath.FILE_UPLOAD}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  },

  async getTempUri(fileAccessKey) {
    const result = await axios.get(`${apiPath.FILE_TEMP_URI}`, {
      params: {
        accessKey: fileAccessKey,
      },
    });
    return result;
  },

  // 공지사항 리스트 조회
  async getNoticeList({ boardId }) {
    const result = await axios.get(`${apiPath.NOTICE_LIST}/${boardId}`);
    return result;
  },

  // 공지사항 상세 조회
  async getNoticeDetail({ postId, boardId }) {
    const result = await axios.get(`${apiPath.NOTICE_DETAIL}/${boardId}/${postId}`);
    return result;
  },

  // 공지사항 상세 조회
  async getDocumentList(params) {
    const result = await axios.get(`${apiPath.DOCUMENT_LIST}`, { params: params });
    return result;
  },

  // 사용자 조회
  async getUserSearch(empIdName) {
    const result = await axios.get(`${apiPath.USER_SERCH}`, { params: { text: empIdName } });
    return result;
  },

  async getIssuePdf(formData) {
    const result = await axios.get(`${apiPath.ISSUE_PDF}`, { params: formData });
    return result;
  },

  async getDocumentHistoryList(formData) {
    const result = await axios.get(`${apiPath.DOCUMENT_HISTROY}`, { params: formData });
    return result;
  },
  async fileDown(formData) {
    const result = await axios.get(`${apiPath.DOCUMENT_DOWN}`, { params: formData });
    return result;
  },
  getDocumentHistoryExcel(formData) {
    return axios.get(`${apiPath.DOCUMENT_EXCEL}`, {
      params: formData,
      responseType: "blob", // blob로 받을 것이기 때문에 전달
    });
  },
  // 구글 토큰 인증
  async authGoogle(code) {
    const result = await axios.get(`${apiPath.AUTH_GOOGLE}`, { params: { code: code } });
    return result;
  },
  // 메일 조회
  async getMail() {
    const result = await axios.get(`${apiPath.MAIL_GOOGLE}`);
    return result;
  },
  // 캘린더 일정 조회
  async getEvent(params) {
    const result = await axios.get(`${apiPath.CALENDAR_GOOGLE}`, { params: params });
    return result;
  },
  // 결재할 문서 목록 조회
  async getApprovalList() {
    const result = await axios.get(`${apiPath.APPROVAL_LIST}`);
    return result;
  },
};
