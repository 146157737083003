export default {
  install(Vue) {
    const HANDLER = "_vue_docClick_handler";
    const DOC = document;

    /* bind */
    function bind(el, binding, vnode, eventType) {
      unbind(el, eventType);

      const vm = vnode.context;

      const callback = binding.value;
      if (typeof callback !== "function") {
        if (process.env.NODE_ENV !== "PROD") {
          Vue.util.warn(
            "v-" +
              binding.name +
              '="' +
              binding.expression +
              '" 은 function 형태로 callback 필요. (현재 반환: <' +
              typeof callback +
              '> "' +
              callback +
              '")',
          );
        }
        return;
      }

      let initialMacrotaskEnded = false;
      setTimeout(function () {
        initialMacrotaskEnded = true;
      }, 0);

      el[HANDLER] = function (ev) {
        const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
        if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
          return callback.call(vm, ev);
        }
      };

      DOC.documentElement.addEventListener(eventType, el[HANDLER], false);
    }

    /* unbind */
    function unbind(el, eventType) {
      DOC.documentElement.removeEventListener(eventType, el[HANDLER], false);
      delete el[HANDLER];
    }

    /**
     * 디렉티브 추가
     */
    Vue.directive("docClick", {
      bind: function (el, binding, vnode) {
        bind(el, binding, vnode, "click");
      },
      update: function (el, binding, vnode) {
        if (binding.value === binding.oldValue) return;
        bind(el, binding, vnode, "click");
      },
      unbind: function (el) {
        unbind(el, "click");
      },
    });

    Vue.directive("docFocusIn", {
      bind: function (el, binding, vnode) {
        bind(el, binding, vnode, "focusin");
      },
      update: function (el, binding, vnode) {
        if (binding.value === binding.oldValue) return;
        bind(el, binding, vnode, "focusin");
      },
      unbind: function (el) {
        unbind(el, "focusin");
      },
    });
  },
};
