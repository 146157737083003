import LogoIntranet from "@/assets/svg/LogoIntranet.vue";
import IconMail from "@/assets/svg/IconMail.vue";
import IconApproval from "@/assets/svg/IconApproval.vue";
import IconCompanyChange from "@/assets/svg/IconCompanyChange.vue";
import IconMessenger from "@/assets/svg/IconMessenger.vue";
import IconErpiu from "@/assets/svg/IconErpiu.vue";
import IconClose from "@/assets/svg/IconClose.vue";
import IconEdit from "@/assets/svg/IconEdit.vue";
import IconReload from "@/assets/svg/IconReload.vue";
import IconAdd from "@/assets/svg/IconAdd.vue";
import IconFold from "@/assets/svg/IconFold.vue";
import IconComplete from "@/assets/svg/IconComplete.vue";

export {
  LogoIntranet,
  IconMail,
  IconApproval,
  IconCompanyChange,
  IconMessenger,
  IconErpiu,
  IconClose,
  IconEdit,
  IconReload,
  IconAdd,
  IconFold,
  IconComplete,
};

//import { LogoIntranet, IconMail, IconCompanyChange, IconMessenger, IconErpiu } from "@/assets/svg"
