const Validate = (function () {
  let validate;
  function _getValidate() {
    if (!validate) {
      validate = this;
      return validate;
    }
    return validate;
  }
  function _urlPattern(value) {
    let regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regex.test(value);
  }
  return {
    getValidate: _getValidate,
    urlPattern: _urlPattern,
  };
})();
export default Validate;
