const isProd = process.env.NODE_ENV === "production";
const ko = {
  message: {
    companyName: "Helinox",
    test: "ko",
    intranet: "인트라넷",
    btn_login: "로그인",
    id_save: "아이디 저장",
    password_reset: "비밀번호 초기화",
    forget_password: "패스워드를 분실하셨으면, ",
    text_try: " 를 하세요",
    btn_edit: "편집",
    btn_add: "추가",
    btn_complete: "완료",
    btn_write: "작성",
    btn_cancel: "취소",
    content_D_1: "Favorite 링크가 없습니다.<br> 즐겨찾기 링크를 추가해보세요!",
    header_D_1: "오늘 하루도<br/>함께 해줘서 고마워요! ",
    btn_password_change: "비밀번호 변경",
    btn_logout: "로그아웃",
    logout_message: "로그아웃하시겠습니까?",
    banner_setting: "전광판 설정",
    banner_D_1:
      "전광판 선택 시 순서변경 및 편집이 가능하며, 미리보기 기능을 통하여 등록될 전광판의 내용을 확인하실 수 있습니다.",
    banner_D_2:
      "전광판 공지는 최대 5개까지 등록 가능하며, 아래 토글 버튼으로 적용여부를 결정할 수 있습니다.",
    banner_D_3: "타이틀 문구를 입력하세요.",
    banner_D_4: "서브타이틀 문구를 입력하세요.",
    banner_D_5: "전광판을 등록해주세요.",
    label_choice: "선택",
    banner_L_1: "전광판 문구",
    label_required: "필수",
    banner_L_2: "타이틀 문구를 입력하세요.",
    banner_L_3: "서브타이틀 문구를 입력하세요.",
    banner_L_4: "인터넷 주소(https://)를 입력하세요",
    banner_L_5: "적용링크",
    btn_temp: "임시저장",
    holiday_L_1: "주요일정",
    holiday_D_1: "이달의 일정이 없습니다.",
    holiday_D_2: "오늘의 일정이 없습니다.",
    btn_correction: "수정",
    btn_delete: "삭제",
    content_L_1: "표기문구 (17자 이내)",
    btn_confirm: "확인",
    content_D_2: "표기문구와 주소를 입력해주세요.",
    content_D_3: "표기문구와 URL형식을 확인해주세요.",
    content_D_4: "표기문구를 확인해주세요.",
    content_D_5: "주소를 확인해주세요.",
    content_D_6: "URL형식을 확인해주세요.",
    banner_image: "배너 이미지 업로드",
    change_confirm: "변경사항을 저장하시겠습니까?",
    upload_image: "JPG, JPEG, PNG, GIF, 파일만 등록 가능합니다.",
    upload_size: "30MB이내",
    btn_file_delete: "파일삭제",
    temp_save_confirm: "임시저장하지 않은 내용은 반영되지 않습니다.\n이대로 진행하시겠습니까?",
    banner_text_confirm: "전광판 문구를 확인해주세요.",
    url_confirm: "URL 형식을 확인해주세요.",
    image_confirm: "이미지를 저장해주세요.",
    temp_save_complete: "임시저장이 완료되었습니다.",
    banner_setting_complete: "전광판 설정을 완료하시겠습니까?",
    banner_setting_complete_confirm: "전광판 설정이 완료되었습니다.",
    btn_preview: "미리보기",
    common_error: "서버에 문제가 생겼습니다. 관리자에게 문의하세요",
    pw_validation: "영어 대 소문자, 숫자, 특수문자만 가능합니다.",
    login_validation: "아이디/비밀번호를 입력해 주세요",
    login_id_validation: "아이디를 입력해 주세요",
    login_pw_validation: "비밀번호를 입력해 주세요",
    admin_validation: "해당 계정은 관리자 권한이 없습니다.",
    btn_close: "닫기",
    content_validation: "표기문구와 주소를 입력해주세요.",
    content_notation_url_validation: "표기문구와 URL형식을 확인해주세요.",
    content_notation_validation: "표기문구를 확인해주세요.",
    content_address_validation: "주소를 확인해주세요",
    content_url_validation: "URL형식을 확인해주세요.",
    menu_edit_confirm: "편집이 완료되었습니다.",
    approval: "전자결재",
    draft: "상신",
    apprBefore: "결재할",
    rejectCount: "반려",
    document: "문서",
    footer_info: "서비스 이용정보",
    intra_admin: "인트라넷 관리자",
    email: "이메일",
    messenger: "메신저 다운로드",
    erp: "더존 ERPIU 다운로드",
    company_select: "법인이동",
    mail_empty: "메일이 없습니다.",
    holiday_T_1: "오늘의 일정",
    select_company: "법인을 선택하세요.",
    btn_move: "이동",
    mail: "메일",
    highTemperature: "어제보다 {temp}°C 높음",
    lowTemperature: "어제보다 {temp}°C 낮음",
    concurrent: "겸임",
    init_google_auth: "구글계정 초기화",
    more: "더보기",
    notice: "공지사항",
    notice_empty: "공지사항이 없습니다.",
    apprBeforeDoc: "결재할 문서",
    apprBeforeDoc_empty: "결재할 문서가 없습니다.",
    receiveMail: "받은 메일",
    receiveMail_empty: "받은 메일이 없습니다.",
  },
  date: {
    mon: "월",
    tue: "화",
    wed: "수",
    thu: "목",
    fri: "금",
    sat: "토",
    sun: "일",
  },
};

export default ko;
