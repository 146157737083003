<template>
  <div class="popup_layer_certificate">
    <div class="inner_popup_layer">
      <div class="layer_head">
        <strong class="title">재직/경력 증명서 발급</strong>
      </div>
      <div class="layer_body">
        <div class="tab_box">
          <ul>
            <li :class="{ active: currentTab === 0 }">
              <a href="javascript:;" @click.prevent="currentTab = 0">발급하기</a>
            </li>
            <li :class="{ active: currentTab === 1 }">
              <a href="javascript:;" @click.prevent="currentTab = 1">내역확인</a>
            </li>
          </ul>
          <button v-if="isAdmin" type="button" class="common_white_btn" @click="openLayerAdmin">
            관리자 전환
          </button>
        </div>
        <div v-show="currentTab === 0" class="tab_content">
          <!--증명서 발급 부분 -->
          <div class="box_tbl">
            <table class="tbl_comm type_row">
              <caption class="ir_caption">
                증명서 발급
              </caption>
              <colgroup>
                <col style="width: 150px" />
                <col style="width: auto" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">증명서 종류 <span class="red_txt">*</span></th>
                  <td>
                    <div class="item_form">
                      <span class="radio-box">
                        <input
                          id="ra1"
                          v-model="documentVo.type"
                          type="radio"
                          name="group1"
                          class="radio-input"
                          value="재직"
                        />
                        <label for="ra1" class="radio-label"> 재직증명서 </label>
                      </span>
                      <span v-if="hasRetirementDate" class="radio-box">
                        <input
                          id="ra2"
                          v-model="documentVo.type"
                          type="radio"
                          name="group1"
                          class="radio-input"
                          value="경력"
                        />
                        <label for="ra2" class="radio-label"> 경력증명서 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">정보 노출 여부 <span class="red_txt">*</span></th>
                  <td>
                    <div class="item_form">
                      <span class="checkbox-box">
                        <input
                          id="ck1"
                          v-model="documentVo.addressYn"
                          type="checkbox"
                          class="checkbox-input"
                          name="name"
                          true-value="Y"
                          false-value="N"
                        />
                        <label for="ck1" class="checkbox-label"> 주소 노출 </label>
                      </span>
                      <span class="checkbox-box">
                        <input
                          id="ck2"
                          v-model="documentVo.residentNumYn"
                          type="checkbox"
                          class="checkbox-input"
                          name="name"
                          true-value="Y"
                          false-value="N"
                        />
                        <label for="ck2" class="checkbox-label"> 주민등록번호 노출 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">언어 <span class="red_txt">*</span></th>
                  <td>
                    <div class="select_area">
                      <span class="select-box">
                        <select v-model="documentVo.lang" class="select-select">
                          <option value="">선택</option>
                          <template v-for="option in languageList">
                            <option
                              :key="option.value"
                              :value="option.value"
                              :disabled="option.disabled"
                            >
                              {{ option.name }}
                            </option>
                          </template>
                        </select>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">직인 포함 여부 <span class="red_txt">*</span></th>
                  <td>
                    <div class="item_form">
                      <span class="radio-box">
                        <input
                          id="ra3"
                          v-model="documentVo.sealYn"
                          type="radio"
                          name="group2"
                          class="radio-input"
                          value="Y"
                          checked="true"
                        />
                        <label for="ra3" class="radio-label"> 직인 포함 </label>
                      </span>
                      <span class="radio-box">
                        <input
                          id="ra4"
                          v-model="documentVo.sealYn"
                          type="radio"
                          name="group2"
                          class="radio-input"
                          value="N"
                        />
                        <label for="ra4" class="radio-label"> 직인 비포함 </label>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">비고</th>
                  <td>
                    <input
                      v-model="documentVo.remark"
                      type="text"
                      placeholder="입력해주세요."
                      class="inp_comm"
                      title="비고 입력"
                      maxlength="30"
                      @keyup="lengthCheck"
                    />
                    <span class="txt_count"
                      ><span class="num_count">{{ remarkLength }}</span
                      >/30</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrap_btn">
            <button type="button" class="btn_comm" @click="closeDocumentLayer">닫기</button>
            <!--  비활성화 : disabled, 알랏 컴포넌트 연결, 메세지 이벤트버스로 전달 -->
            <button type="button" class="btn_comm pdf_btn" @click="issuePdf">PDF 발급</button>
          </div>
        </div>
        <div v-show="currentTab === 1" class="tab_content">
          <!--총 조회건수 부분 -->
          <div class="total_count_area">
            <p class="total_count">
              총<span>{{ total }}</span> 건
            </p>
          </div>
          <!-- 발급내역 표 -->
          <div class="box_tbl">
            <table v-if="hasResult" class="tbl_comm">
              <caption class="ir_caption">
                발급내역 목록
              </caption>
              <colgroup>
                <col style="width: 5%" />
                <col style="width: 11%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">NO</th>
                  <th scope="col">문서번호</th>
                  <th scope="col">구분</th>
                  <th scope="col">언어</th>
                  <th scope="col">소속</th>
                  <th scope="col">직위</th>
                  <th scope="col">직책</th>
                  <th scope="col">주소노출</th>
                  <th scope="col">주민번호 노출</th>
                  <th scope="col">직인 포함</th>
                  <th scope="col">비고</th>
                  <th scope="col">발급일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in documentList" :key="index">
                  <td>{{ item.idx }}</td>
                  <td>{{ item.documentNum }}</td>
                  <td>{{ item.type }} 증명서</td>
                  <td>{{ item.lang }}</td>
                  <td>{{ item.deptName || "-" }}</td>
                  <td>{{ item.position || "-" }}</td>
                  <td>{{ item.duty || "-" }}</td>
                  <td>{{ item.addressYn }}</td>
                  <td>{{ item.residentNumYn }}</td>
                  <td>{{ item.sealYn }}</td>
                  <td>
                    <!-- 툴팁 부분 -->
                    <div class="tooltip_box">
                      <p>{{ item.remark }}</p>
                      <div class="tooltip_text">{{ item.remark }}</div>
                    </div>
                  </td>
                  <td>
                    {{ setDate(item.createDate) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-else class="tbl_comm">
              <caption class="ir_caption">
                발급내역 목록
              </caption>
              <colgroup>
                <col style="width: 5%" />
                <col style="width: 11%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
                <col style="width: 8%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">NO</th>
                  <th scope="col">문서번호</th>
                  <th scope="col">구분</th>
                  <th scope="col">언어</th>
                  <th scope="col">소속</th>
                  <th scope="col">직위</th>
                  <th scope="col">직책</th>
                  <th scope="col">주소노출</th>
                  <th scope="col">주민번호 노출</th>
                  <th scope="col">직인 포함</th>
                  <th scope="col">비고</th>
                  <th scope="col">발급일자</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="12" class="empty_data">
                    <div class="inner_g">
                      <span class="ico_info_type2"></span>
                      <p class="txt_empty">발급 이력이 없습니다.</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 페이징 부분 -->
          <pagenation
            v-if="page.pageCount > 1"
            :current="page.current"
            :page-count="page.pageCount"
            :page-size="page.pageSize"
            :range-size="page.rangeSize"
            @select="onClickTurnPage"
          />
        </div>
      </div>
      <!-- 닫기 버튼 -->
      <button class="layer-btn-close" @click="closeDocumentLayer">
        <SvgIcon class="svg--type-close" :viewBox="'0 0 32 32'"><IconClose /></SvgIcon>
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import { IconClose } from "@/assets/svg";
import { EventBus } from "../plugins/eventBus";
import Pagenation from "@/components/Pagination";
import * as types from "@/store/mutation-type";
import { mapActions, mapGetters, mapState } from "vuex";
import Api from "@/api";

export default {
  name: "DocumentLayer",
  components: {
    SvgIcon,
    IconClose,
    Pagenation,
  },
  data() {
    return {
      currentTab: 0,
      isFocus: false,
      remarkLength: 0,
      documentVo: {
        accountId: "",
        name: "",
        companyId: "",
        type: "재직",
        adminYn: "Y",
        residentNumYn: "N",
        addressYn: "N",
        sealYn: "Y",
        lang: "",
        remark: "",
      },
      page: {
        current: 1,
        pageCount: 0,
        pageSize: 10,
        rangeSize: 10,
      },
      documentList: [],
      total: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["isAdmin"]),
    hasRetirementDate() {
      return (
        this.userInfo.retirementDate !== "99991231" && this.userInfo.retirementDate !== "29991231"
      );
    },
    hasResult() {
      return this.documentList.length > 0;
    },
    ...mapState({
      languageList: (state) => {
        return state.languageList.map((item) => {
          return {
            value: item.code,
            name: item.name,
          };
        });
      },
    }),
  },
  created() {
    this.getLanguageList();
  },
  mounted() {
    this.searchList();
  },
  methods: {
    ...mapActions(["getLanguageList"]),
    closeDocumentLayer() {
      this.$emit("closeDocumentLayer");
    },
    setDate(date) {
      const year = date[0];
      let month = date[1] > 10 ? date[1] : `0${date[1]}`;
      const day = date[2] > 10 ? date[2] : `0${date[2]}`;
      return `${year}-${month}-${day}`;
    },
    async fileDown(fileName) {
      console.log("fileName2 :" + fileName);
      window.open("/api/document/fileDown?fileName=" + fileName);
      await Api.fileDown({ fileName })
        .then((res) => {
          if (res.status === 200) {
            EventBus.$emit("open:documentAlert", {
              message: "신청한 문서가 PDF파일로 다운로드 되었습니다.",
            });
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    onClickTurnPage(page) {
      this.searchList(page);
    },
    async issuePdf() {
      let flag = true;
      if (flag && this.documentVo.lang === "") {
        EventBus.$emit("open:documentAlert", {
          message: "언어를 선택해주세요.",
        });
        flag = false;
      }
      if (flag) {
        this.documentVo.accountId = this.userInfo.accountId;
        this.documentVo.name = this.userInfo.name;
        await Api.getIssuePdf(this.documentVo)
          .then((res) => {
            if (res.status === 200 && res.data.result !== null) {
              const fileName = res.data.result;
              this.cancel();
              setTimeout(() => {
                this.fileDown(fileName);
              }, 500);
            } else {
              console.log("자체 500 error");
              EventBus.$emit("open:documentAlert", {
                message: res.data.message.length > 1 ? res.data.message : "관리자에게 문의하세요.",
              });
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }
    },
    //취소 버튼
    cancel() {
      this.documentVo.accountId = "";
      this.documentVo.name = "";
      this.accountId = "";
      this.empIdName = "";
      this.userListInfo = [];

      this.retirementYn = "";
      this.documentVo.type = "재직";
      this.documentVo.residentNumYn = "N";
      this.documentVo.addressYn = "N";
      this.documentVo.sealYn = "Y";
      this.documentVo.lang = "";
      this.documentVo.remark = "";

      this.isFocus = false;
    },
    lengthCheck(e) {
      this.remarkLength = e.target.value.length;
    },
    async searchList(page) {
      const param = {
        page: page || this.page.current,
        size: this.page.pageSize,
        accountId: this.userInfo.accountId,
      };

      const result = await this.$Api.getDocumentList(param);
      if (result.status === 200) {
        // this.documentList = result.data.result.content;
        const contData = result.data.result.content;
        const _idxStart =
          result.data.result.totalElements - result.data.result.number * this.page.pageSize;
        const { number, totalPages, totalElements } = result.data.result;
        this.page.current = number + 1;
        this.page.pageCount = totalElements;
        console.log(this.page);
        let _idxCount = 0;
        this.total = totalElements;
        this.documentList = [];
        contData.forEach((item) => {
          this.documentList.push({
            idx: _idxStart - _idxCount,
            ...item,
          });
          _idxCount += 1;
        });
      } else {
      }
      console.log(result);
    },
    openLayerAdmin() {
      this.$emit("openLayerAdmin");
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      border-radius: 50%;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border : {
        width: 1px;
        style: solid;
        color: #d9d9d9;
      }
      background-color: #ffffff;
      border-radius: 50%;
    }
    &::after {
      left: 50%;
      top: 50%;
      width: 6px;
      height: 6px;
      background-color: transparent;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::before {
        border-color: #fde0e8;
      }
    }
    &:checked {
      &::before {
        background-color: #fde0e8;
        border-color: #fde0e8;
      }
      &::after {
        background-color: #333333;
      }
      & + .radio-label {
        color: #3c3c3c;
      }
    }
    &:disabled {
      &::before {
        border-color: #e1e1e1;
        background-color: #f5f5f5;
      }
    }
  }
  &-label {
    display: inline-block;
    padding: 0 8px;
    color: #777777;
    font : {
      size: 13px;
      weight: 500;
    }
    vertical-align: middle;
  }
}
.checkbox {
  &-box {
    position: relative;
    display: inline-block;
  }
  &-input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border : {
        width: 1px;
        style: solid;
        color: #d9d9d9;
        radius: 2px;
      }
      background-color: #ffffff;
    }
    &::after {
      left: 50%;
      top: 50%;
      width: 11px;
      height: 8px;
      background {
        color: transparent;
      }
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::before {
        border-color: #fde0e8;
      }
    }
    &:checked {
      &::before {
        background-color: #fde0e8;
        border-color: #fde0e8;
      }
      &::after {
        background: {
          image: url(~@/assets/svg/iconCheck.svg);
          position: 0 0;
          repeat: no-repeat;
          size: 11px 8px;
        }
      }
      & + .checkbox-label {
        color: #3c3c3c;
      }
    }
    &:disabled {
      &::before {
        border-color: #e1e1e1;
        background-color: #f5f5f5;
      }
      &::after {
        filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(106%) contrast(85%);
      }
    }
  }
  &-label {
    display: inline-block;
    padding: 0 8px;
    color: #777777;
    font : {
      size: 13px;
      weight: 500;
    }
    vertical-align: middle;
  }
}
svg {
  &--type {
    &-close {
      --svg-width: 32px;
      --svg-fiil: #000000;
    }
  }
}
</style>
