<template>
  <box-category :is-empty-data="mailList.length === 0">
    <template #head>
      <h3 class="tit_box">{{ $t("message.receiveMail") }}</h3>
      <button type="button" class="btn_refresh" @click="getMailData">
        <span class="ico_comm ico_refresh">{{ $t("message.refresh") }}</span>
      </button>
    </template>
    <template #cont>
      <ul class="list-group">
        <li v-for="(item, idx) in mailList" :key="idx" class="list-group-item">
          <span class="item_txt">
            <a class="txt_item" href="javascript:;" @click.prevent="openMailDetail(item.mailId)">
              {{ _.unescape(item.title) }} - {{ _.unescape(item.summaryContent) }}
            </a>
          </span>
        </li>
      </ul>
    </template>
    <template #contEmpty>
      <p class="txt_none">{{ $t("message.receiveMail_empty") }}</p>
    </template>
    <template #side>
      <button type="button" class="btn_g" @click="goToMailSite">
        {{ $t("message.more") }}
      </button>
    </template>
  </box-category>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoxCategory from "@/components/main/content/BoxCategory";

export default {
  name: "ContentMail",
  components: { BoxCategory },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo", "mailList"]),
  },
  mounted() {
    this.getMail();
  },
  methods: {
    ...mapActions(["getMail"]),
    /**
     * get: 메일 목록
     */
    getMailData() {
      if (this.userInfo.googleAccessToken !== null) {
        this.getMail();
      }
    },
    /**
     * link: 구글 메일 상세 (새 탭)
     * @param mailId
     */
    openMailDetail(mailId) {
      window.open(`https://mail.google.com/mail/u/0/?tab=rm#inbox/${mailId}`);
    },
    /**
     * link: 구글 메일함
     */
    goToMailSite() {
      window.open(`https://mail.google.com/mail/u/0/?tab=rm#inbox`);
    },
  },
};
</script>
