import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import * as types from "./mutation-type";
import Api from "@/api";
import { Trans } from "@/translations";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    user: {
      accountId: "",
      authority: "",
      companyId: "",
      companyList: [],
      departmentName: "",
      empNo: "",
      imgUrl: "",
      lastLoginAt: "",
      name: "",
      duty: "", //직책
      rankCode: "", //직급
      position: "", //직위
      initLogin: "", //당일 첫 로그인일 때 false
      lastPwdChanged: "", //마지막 비밀번호 변경된지 90일 초과시 false
      retirementDate: "", //퇴사일
      accountInfo: "",
      googleAccessToken: "",
      concurrentList: [],
    },
    isAdmin: "",
    workTime: [
      {
        requireTime: "0",
        planTime: "0",
        recordTime: "0",
      },
    ],
    eventHoliday: [],
    userPoint: [],
    userVacation: {
      annualVacList: [],
      projectVacList: [],
    },
    weathers: [],
    bannerList: [],
    bannerDisplay: [],
    workMenuList: [],
    officeMenuList: [],
    favoriteMenuList: [],
    languageCode: "ko",
    languageList: [],
    noticeList: [],
    noticeDetail: "",
    mailList: [],
    mainEventList: [],
    todayEventList: [],
    approvalList: [],
  },
  mutations: {
    [types.SET_USER_DATA](state, payload) {
      state.user.accountId = payload.accountId;
      state.user.authority = payload.authority;
      state.user.companyId = payload.companyId;
      state.user.companyList = payload.companyList;
      state.user.departmentName = payload.departmentName;
      state.user.empNo = payload.empNo;
      state.user.imgUrl = payload.imgUrl;
      state.user.lastLoginAt = payload.lastLoginAt;
      state.user.name = payload.name;
      state.user.duty = payload.duty;
      state.user.rankCode = payload.rankCode;
      state.user.position = payload.position;
      state.user.initLogin = payload.initLogin;
      state.user.lastPwdChanged = payload.lastPwdChanged;
      state.user.retirementDate = payload.retirementDate;
      state.user.accountInfo = payload.accountInfo;
      state.user.googleAccessToken = payload.googleAccessToken;
      state.user.concurrentList = payload.concurrentList;
    },
    [types.SET_PERMISSION](state, payload) {
      state.isAdmin = payload;
    },
    [types.SET_WORK_TIME](state, payload) {
      state.workTime = payload;
    },
    [types.SET_EVENT_HOLIDAY](state, payload) {
      state.eventHoliday = payload;
    },
    [types.SET_USER_POINT](state, payload) {
      state.userPoint = payload;
    },
    [types.SET_USER_VACATION](state, payload) {
      state.userVacation = payload;
    },
    [types.SET_WEATHER](state, payload) {
      state.weathers = payload;
    },
    [types.SET_BANNER](state, payload) {
      state.bannerList = payload;
    },
    [types.SET_BANNER_DISPLAY](state, payload) {
      state.bannerDisplay = payload;
    },
    [types.SET_WORK_MENU_LIST](state, payload) {
      state.workMenuList = payload;
    },
    [types.SET_OFFICE_MENU_LIST](state, payload) {
      state.officeMenuList = payload;
    },
    [types.SET_FAVORITE_MENU_LIST](state, payload) {
      state.favoriteMenuList = payload;
    },
    [types.CHANGE_LANGUAGE](state, payload) {
      //언어변경
      state.languageCode = payload;
    },
    [types.SET_LANGUAGE_LIST](state, payload) {
      //언어변경
      state.languageList = payload;
    },
    [types.SET_NOTICE_LIST](state, payload) {
      state.noticeList = payload || [];
    },
    [types.SET_NOTICE_DETAIL](state, payload) {
      state.noticeDetail = payload;
    },
    [types.SET_MAIL_LIST](state, payload) {
      state.mailList = payload || [];
    },
    [types.SET_MAIN_EVENT_LIST](state, payload) {
      state.mainEventList = payload || [];
    },
    [types.SET_TODAY_EVENT_LIST](state, payload) {
      state.todayEventList = payload || [];
    },
    [types.SET_APPROVAL_LIST](state, payload) {
      state.approvalList = payload || [];
    },
  },
  actions: {
    async getUser({ commit, dispatch }) {
      const response = await Api.getUser();
      commit(types.SET_USER_DATA, response.data.result);
      commit(types.SET_PERMISSION, response.data.result.authority === "ADMIN" ? true : false);
    },
    async getWorkTime({ commit }) {
      const response = await Api.getWorkTime();
      commit(types.SET_WORK_TIME, response.data.result);
    },
    async getEventHoliday({ commit }, params) {
      const response = await Api.getEventHoliday(params);
      commit(types.SET_EVENT_HOLIDAY, response.data.result);
    },
    async getSelectPoint({ commit }) {
      const response = await Api.getSelectPoint();
      commit(types.SET_USER_POINT, response.data.result);
    },
    async getUserVacList({ commit }) {
      const response = await Api.getUserVacList();
      commit(types.SET_USER_VACATION, response.data.result);
    },
    async getWeather({ commit }) {
      const response = await Api.getWeather();
      commit(types.SET_WEATHER, response.data.result);
    },
    async getBanner({ commit }) {
      const response = await Api.getBanner();
      commit(types.SET_BANNER, response.data.result);
    },
    async getBannerDisplay({ commit }) {
      const response = await Api.getBannerDisplay();
      commit(types.SET_BANNER_DISPLAY, response.data.result);
    },
    async getMenuWork({ commit }, type) {
      const response = await Api.getMenu(type);
      commit(types.SET_WORK_MENU_LIST, response.data.result);
    },
    async getMenuOffice({ commit }, type) {
      const response = await Api.getMenu(type);
      commit(types.SET_OFFICE_MENU_LIST, response.data.result);
    },
    async getMenuFavorite({ commit }) {
      const response = await Api.getFavorite();
      commit(types.SET_FAVORITE_MENU_LIST, response.data.result);
    },

    async getLanguageList({ commit }) {
      //언어 리스트 API 나오면 여기서 추가
      //const response = await Api;
      const languageList = [
        { name: "한국어", code: "ko" },
        { name: "English", code: "en" },
      ];
      commit(types.SET_LANGUAGE_LIST, languageList);
    },
    async getNoticeList({ commit }, payload) {
      const response = await Api.getNoticeList(payload);
      commit(types.SET_NOTICE_LIST, response.data.result);
    },
    async getNoticeDetail({ commit }, payload) {
      const response = await Api.getNoticeDetail(payload);
      commit(types.SET_NOTICE_DETAIL, response.data.result);
    },
    // 메일 목록 조회
    async getMail({ commit }) {
      const response = await Api.getMail();
      commit(types.SET_MAIL_LIST, response.data.result);
    },
    // 캘린더 일정 목록 조회
    async getEvent({ commit }, params) {
      const response = await Api.getEvent(params);
      if (params.calendarCode === "MAIN") {
        commit(types.SET_MAIN_EVENT_LIST, response.data.result);
      } else {
        commit(types.SET_TODAY_EVENT_LIST, response.data.result);
      }
    },
    // 결재할 문서 목록 조회
    async getApprovalList({ commit }) {
      const response = await Api.getApprovalList();
      commit(types.SET_APPROVAL_LIST, response.data.result);
    },
  },
  getters: {
    isAdmin(state) {
      return state.isAdmin;
    },
    userInfo(state) {
      return state.user;
    },
    workTime(state) {
      return state.workTime;
    },
    eventHoliday(state) {
      return state.eventHoliday;
    },
    userPoint(state) {
      return state.userPoint;
    },
    userVacation(state) {
      return state.userVacation;
    },
    weathers(state) {
      return state.weathers;
    },
    bannerList(state) {
      return state.bannerList;
    },
    bannerDisplay(state) {
      return state.bannerDisplay;
    },
    workMenuList(state) {
      return state.workMenuList;
    },
    officeMenuList(state) {
      return state.officeMenuList;
    },
    favoriteMenuList(state) {
      return state.favoriteMenuList;
    },
    noticeList(state) {
      return state.noticeList;
    },
    noticeDetail(state) {
      return state.noticeDetail;
    },
    mailList(state) {
      return state.mailList;
    },
    mainEventList(state) {
      return state.mainEventList;
    },
    todayEventList(state) {
      return state.todayEventList;
    },
    approvalList(state) {
      return state.approvalList;
    },
  },
  modules: {},

  // plugins: [createPersistedState()],
});
