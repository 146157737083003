<template functional>
  <span class="txt_sub emph_color">
    <!-- @slot 에러 텍스트 출력 -->
    <slot>Error</slot>
  </span>
</template>
<script>
/**
 * 에러 텍스트 컴포넌트
 * @displayName txt-error
 */
export default {
  name: "TxtError",
};
</script>
