<template>
  <div v-if="isOpen" class="toast_popup" :class="{ toast_popup_storage: isPopup }">
    <p class="txt_popup">{{ toastMessage }}</p>
  </div>
</template>

<script>
import { EventBus } from "../../plugins/eventBus";

export default {
  name: "Toast",
  data() {
    return {
      isPopup: false, // 팝업 토스트인지 체크
      toastMessage: "", // 토스트 메세지
      isOpen: false, // 토스트 활성화여부
    };
  },
  mounted() {
    EventBus.$on("open:toast", this.openToast);
    EventBus.$on("close:toast", this.closeToast);
  },
  methods: {
    openToast(data) {
      this.isPopup = data.popup;
      this.toastMessage = data.message;
      this.isOpen = true;
      setTimeout(() => {
        this.closeToast();
      }, 3000);
    },
    closeToast() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>

</style>
