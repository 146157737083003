<template functional>
  <span class="txt_sub">
    <!-- @slot input 하단 설명 텍스트 출력 -->
    <slot></slot>
  </span>
</template>
<script>
/**
 * input 하단 설명 텍스트 컴포넌트
 * @displayName txt-sub
 */
export default {
  name: "TxtSub",
};
</script>
