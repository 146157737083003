<template>
  <menu-list :type="type" :list="workMenuList"> </menu-list>
</template>

<script>
import { mapGetters } from "vuex";
import MenuList from "./MenuList";

export default {
  name: "ContentWork",
  components: { MenuList },
  data() {
    return {
      type: "WORK",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["workMenuList"]),
  },
};
</script>

<style scoped></style>
