<template>
  <li role="presentation">
    <router-link
      v-if="item.tag === 'router-link'"
      :aria-selected="$route.path === item.to"
      :to="item.to"
      class="link_tab"
      role="tab"
    >
      {{ item.label }}
    </router-link>
    <a
      v-else
      :aria-selected="selected ? 'true' : 'false'"
      :href="item.href || '#none'"
      class="link_tab"
      role="tab"
      @click="eventClick"
    >
      {{ item.label }}
    </a>
  </li>
</template>
<script>
const TAB_ITEM_TAG = {
  A: "a",
  ROUTER_LINK: "router-link",
};

/**
 * 탭 아이템 컴포넌트
 * @displayName tab-item
 */

export default {
  name: "TabItem",
  props: {
    /**
     * 각 탭 정보
     */
    item: {
      type: [Object, Array],
      default: () => {},
    },
    /**
     * 탭메뉴 형식에 따른 태그 정보
     */
    tag: {
      type: String,
      default: "a",
      validator: (value) => Object.keys(TAB_ITEM_TAG).find((key) => TAB_ITEM_TAG[key] === value),
    },
    /**
     * 탭메뉴 경로 a 태그 형일 때
     */
    href: {
      type: String,
      default: "#none",
    },
    /**
     * 탭메뉴 경로 라우터 일 때
     */
    to: {
      type: String,
      default: "",
    },
    /**
     * 선택 여부
     */
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    eventClick(e) {
      if (!this.item.href) e.preventDefault();
      this.$emit("click");
    },
  },
};
</script>
