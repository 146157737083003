<template>
  <div v-if="isOpenPopup" class="popup_layer popup_layer_display">
    <div class="inner_popup_layer">
      <div class="layer_head">
        <strong class="tit_display"> {{ $t("message.banner_setting") }}</strong>
      </div>
      <div class="layer_body">
        <div class="display_info">
          <ul class="list_display">
            <li>{{ $t("message.banner_D_1") }}</li>
            <li>{{ $t("message.banner_D_2") }}</li>
          </ul>
          <div v-if="type === 'IMG'" class="preview_display">
            <em class="badge_preview">{{ $t("message.btn_preview") }}</em>
            <img v-if="imgUrl" :src="imgUrl" :alt="fileName" />
          </div>
          <div v-else class="preview_display">
            <em class="badge_preview">{{ $t("message.btn_preview") }}</em>
            <strong class="tit_preview">{{ title || $t("message.banner_D_3") }}</strong>
            <p class="txt_preview">{{ titleSub || $t("message.banner_D_4") }}</p>
          </div>
        </div>
        <div class="display_board">
          <div class="area_list">
            <ul class="list_group">
              <draggable :list="bannerList" :move="checkMove" class="drag-group">
                <transition-group>
                  <li
                    v-for="item in bannerList"
                    :key="item.id"
                    class="list-group-item"
                    :class="{ on: item.selectedRow }"
                    @click="selectRow(item.id)"
                  >
                    <span class="ico_comm ico_move">이동</span>
                    <span class="item_txt">
                      <span v-if="item.type === 'IMG'" class="txt_item">
                        {{ item.fileName.trim() ? item.fileNameString : $t("message.banner_D_5") }}
                      </span>
                      <span v-else class="txt_item">
                        {{ item.title.trim() ? item.title : $t("message.banner_D_5") }}
                      </span>
                    </span>
                    <label class="lab_swich" :class="{ switch_on: item.display }">
                      <input type="checkbox" class="inp_swich" />
                      <span class="slider_swich" @click="toggleDisplay(item)"></span>
                      <span class="screen_out"> {{ $t("message.label_choice") }}</span>
                    </label>
                  </li>
                </transition-group>
              </draggable>
            </ul>
          </div>
          <div class="area_setting">
            <div class="screen_out">전광판 입력정보 입력폼</div>
            <!-- 토글버튼 영역-->
            <!--            <button style="border: 1px solid #000; padding: 5px;" @click="toggleTextImg">{{ type }}</button>-->

            <label class="lab_swich img_toggle_btn" :class="{ switch_on: type === 'IMG' }">
              <input type="checkbox" class="inp_swich" />
              <span class="slider_swich"> <!-- 221222 프로토콜 상이함으로 임시로 제거 @click="toggleTextImg" -->
                <span class="toggle_text" :class="{ img_on: type === 'IMG' }">{{
                  type === "IMG" ? "Img" : "Text"
                }}</span></span
              >
            </label>
            <div v-if="type === 'TEXT'" class="setting_area">
              <em class="tit_setting"
                >{{ $t("message.banner_L_1") }}(<sup class="emph_g">*</sup
                >{{ $t("message.label_required") }})</em
              >
              <span class="wrap_inp">
                <span class="item_inp_type1" :class="{ error_inp: isErrorTitle }">
                  <input
                    v-model="title"
                    type="text"
                    :placeholder="$t('message.banner_L_2')"
                    class="inp_comm"
                    title="타이틀 입력"
                    maxlength="16"
                  />
                </span>
                <span class="txt_count"
                  ><span class="num_count">{{ title.length }}</span
                  >/16</span
                >
              </span>
              <span class="wrap_inp">
                <span class="item_inp_type1" :class="{ error_inp: isErrorTitleSub }">
                  <input
                    v-model="titleSub"
                    type="text"
                    :placeholder="$t('message.banner_L_3')"
                    class="inp_comm"
                    title="서브 타이틀 입력"
                    maxlength="20"
                  />
                </span>
                <span class="txt_count"
                  ><span class="num_count">{{ titleSub.length }}</span
                  >/20</span
                >
              </span>
              <p v-show="isErrorTitle || isErrorTitleSub" class="txt_error">{{ errorText }}</p>
            </div>
            <!-- 이미지 영역 -->
            <div v-else class="setting_area">
              <em class="tit_setting"
                >{{ $t("message.banner_image") }}(<sup class="emph_g">*</sup
                >{{ $t("message.label_required") }})</em
              >
              <div class="file_area">
                <label for="upload01" class="file_label"
                  ><span class="screen_out">파일추가</span></label
                >
                <input
                  id="upload01"
                  ref="image"
                  type="file"
                  name="image"
                  accept="image/*"
                  class="file_upload"
                  @change="uploadImage"
                />
                <span v-if="fileName" class="file_name_area">{{ fileName }}</span>
                <span v-else class="file_guide_area"
                  >{{ $t("message.upload_image") }} <br />/ {{ $t("message.upload_size") }}
                </span>
                <button v-if="fileAccessKey" class="file_del_btn" @click="removeFile">
                  <span class="screen_out">{{ $t("message.btn_file_delete") }} </span>
                </button>
              </div>
            </div>
            <div class="setting_area">
              <em class="tit_setting">{{ $t("message.banner_L_5") }} ({{ $t("message.label_choice") }})</em>
              <div class="wrap_inp setting_type1">
                <span class="item_inp_type1" :class="{ error_inp: isErrorUrl }">
                  <input
                    v-model="url"
                    type="text"
                    :placeholder="$t('message.banner_L_4')"
                    class="inp_comm"
                    title="인터넷주소 입력"
                  />
                </span>
                <p v-show="isErrorUrl" class="txt_error">{{ errorUrlText }}</p>
              </div>
            </div>
            <div class="group_btn">
              <button type="button" class="btn_g" @click="tempSave">
                {{ $t("message.btn_temp") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button type="reset" class="btn_comm" @click="closePopup">
            {{ $t("message.btn_cancel") }}
          </button>
          <button type="submit" class="btn_comm btn_comm2" @click="confirmPopup">
            {{ $t("message.btn_complete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import { mapGetters, mapActions } from "vuex";
import Validate from "@/plugins/Validate";
import { EventBus } from "@/plugins/eventBus";
export default {
  name: "Banner",
  // display: "Simple",
  // order: 0,
  components: {
    draggable,
  },
  data() {
    return {
      title: "", // 타이틀
      titleSub: "", // 서브타이틀
      url: "", // 링크 Url
      fileAccessKey: "",
      type: "",
      isErrorTitle: false, // 타이틀 애러여부
      isErrorTitleSub: false, // 서브타이틀 애러여부
      errorText: "", // 애러문구
      isErrorUrl: false, // Url 애러여부
      errorUrlText: "", // Url 애러문구
      isOpenPopup: false, // 전광판 팝업 오픈여부
      selectItemId: 1, // 선택한 메뉴아이디
      selectRowData: {}, // 선택한 메뉴데이터
      isDoubleCheck: false, // 버튼 더블클릭 체크
      fileName: "",
      fileNameString: "",
      imgUrl: "",
    };
  },
  computed: {
    ...mapGetters(["bannerList"]),
  },
  mounted() {
    EventBus.$on("open:banner", this.openPopup);
    this.getBanner();
  },
  methods: {
    ...mapActions(["getBannerDisplay", "getBanner"]),
    // 초기 리스트 구성
    initBannerList() {
      for (let i = 0; i < 5; i++) {
        let emptyData = [];
        // 최초 배너리스트가 있다면 해당 데이터에 빈 데이터를 추가하며, 기존 데이터의 priority중 가장 큰 수에 +1을 하여 순서를 지정한다.
        if (this.bannerList) {
          if (!this.bannerList[i]) {
            const tempId =
              this.bannerList.length > 0
                ? this.bannerList.slice(0).sort(function (a, b) {
                    return a.id < b.id;
                  })[this.bannerList.length - 1].id + 1
                : 1;
            emptyData = {
              id: tempId,
              title: "",
              titleSub: "",
              url: "",
              display: false,
              priority: i + 1,
              selectedRow: false,
              open: true,
              deleteFlag: false,
              type: "TEXT",
              fileAccessKey: "",
            };
            this.bannerList.push(emptyData);
          }
        } else {
          emptyData = {
            id: i + 1,
            title: "",
            titleSub: "",
            url: "",
            display: false,
            priority: i + 1,
            selectedRow: false,
            open: true,
            deleteFlag: false,
            type: "TEXT",
            fileAccessKey: "",
          };
          this.bannerList.push(emptyData);
        }
        // Api에서 boolean 값이 true로 떨어지는 값을 boolean으로 변경
        this.bannerList[i].display =
          this.bannerList[i].display.toString() === "true" ? true : false;
        this.bannerList[i].selectedRow = false;
      }
      console.log(this.bannerList);
      this.bannerList.forEach((ba) => {
        const fileName = ba.fileName;
        if (fileName && fileName.indexOf(".") != -1) {
          ba.fileNameString = fileName.split(".")[0];
        } else {
          ba.fileNameString = "";
        }
      });
      // 가장 처음 데이터를 선택
      this.bannerList[0].selectedRow = true;
      // 가장 처음 데이터로 세팅
      this.selectItemId = this.bannerList[0].id;
      this.title = this.bannerList[0].title;
      this.titleSub = this.bannerList[0].titleSub;
      this.url = this.bannerList[0].url;
      this.type = this.bannerList[0].type;
      this.fileName = this.bannerList[0].fileName;
      this.fileAccessKey = this.bannerList[0].fileAccessKey;
      this.imgUrl = this.bannerList[0].imgUrl;
    },
    // 팝업열기
    openPopup() {
      this.initBannerList();
      this.isOpenPopup = true;
      this.isErrorTitle = false;
      this.isErrorTitleSub = false;
      this.isErrorUrl = false;
    },
    // 팝업닫기
    closePopup() {
      this.isOpenPopup = false;
    },
    // 현재 행에서 변경한 값이 있는지 체크
    hasChangeRowData() {
      for (let i = 0; i < 5; i++) {
        if (this.bannerList[i].selectedRow) {
          if (
            this.bannerList[i].title !== this.title ||
            this.bannerList[i].titleSub !== this.titleSub ||
            this.bannerList[i].url !== this.url ||
            this.bannerList[i].fileAccessKey !== this.fileAccessKey ||
            this.bannerList[i].type !== this.type ||
            this.bannerList[i].fileName !== this.fileName ||
            this.bannerList[i].imgUrl !== this.imgUrl
          ) {
            return true;
          }
        }
      }
      return false;
    },
    // 건별 데이터 변경
    changeRowData(id) {
      this.selectRowData = {
        title: this.title,
        titleSub: this.titleSub,
        url: this.url,
        beforeId: this.selectItemId,
        selectId: id,
        type: this.type,
        imgUrl: this.imgUrl,
      };
      this.selectItemId = id;
      this.setSelectRowData();
      this.isErrorTitle = false;
      this.isErrorTitleSub = false;
      this.isErrorUrl = false;
    },
    // 데이터 로우 선택
    selectRow(id) {
      if (this.selectItemId === id) {
        return false;
      }

      if (this.hasChangeRowData()) {
        EventBus.$emit("open:confirm", {
          message: this.$t("message.temp_save_confirm"),
          name: "tempbanner",
          onConfirm: () => {
            this.changeRowData(id);
          },
        });
        return false;
      }

      this.changeRowData(id);
    },
    // 임시저장 버튼
    tempSave() {
      if (!this.isDoubleCheck) {
        if (this.type === "TEXT" && !this.title.trim()) {
          this.isErrorTitle = true;
          this.errorText = this.$t("message.banner_text_confirm");
        } else {
          this.isErrorTitle = false;
          this.errorText = "";
        }
        if (this.type === "TEXT" && !this.titleSub.trim()) {
          this.isErrorTitleSub = true;
          this.errorText = this.$t("message.banner_text_confirm");
        } else {
          this.isErrorTitleSub = false;
          if (this.type === "TEXT" && !this.title.trim()) {
            this.isErrorTitle = true;
            this.errorText = this.$t("message.banner_text_confirm");
          } else {
            this.errorText = "";
          }
        }
        if (this.url && !Validate.urlPattern(this.url)) {
          this.isErrorUrl = true;
          this.errorUrlText = this.$t("message.url_confirm");
        } else {
          this.isErrorUrl = false;
          this.errorUrlText = "";
        }
        if (
          this.type === "TEXT" &&
          (!this.title.trim() ||
            !this.titleSub.trim() ||
            (this.url && !Validate.urlPattern(this.url)))
        ) {
          return false;
        }
        if (this.type === "IMG" && !this.fileAccessKey) {
          EventBus.$emit("open:toast", { popup: true, message: this.$t("message.image_confirm") });
          return false;
        }
        for (let i = 0; i < 5; i++) {
          if (this.bannerList[i].id == this.selectItemId) {
            this.bannerList[i].title = this.title;
            this.bannerList[i].titleSub = this.titleSub;
            this.bannerList[i].url = this.url;
            this.bannerList[i].fileAccessKey = this.fileAccessKey;
            this.bannerList[i].type = this.type;
            this.bannerList[i].fileName = this.fileName;
            this.bannerList[i].fileNameString = this.fileNameString;
            this.bannerList[i].imgUrl = this.imgUrl;
          }
        }
        EventBus.$emit("open:toast", {
          popup: true,
          message: this.$t("message.temp_save_complete"),
        });
      }
    },
    // 최종저장 "완료"버튼
    confirmPopup() {
      const checkConfirm = () => {
        if (!this.isDoubleCheck) {
          EventBus.$emit("open:confirm", {
            message: this.$t("message.banner_setting_complete"),
            name: "banner",
            onConfirm: this.saveBanner,
          });
        }
      };

      if (this.hasChangeRowData()) {
        EventBus.$emit("open:confirm", {
          message: this.$t("message.temp_save_confirm"),
          name: "tempbanner",
          onConfirm: () => {
            setTimeout(() => {
              checkConfirm();
            }, 0);
          },
        });
        return false;
      }

      checkConfirm();
    },
    // 최종저장 컨펌 팝업레이어의 "예"를 누를시 동작
    saveBanner() {
      this.isDoubleCheck = true;
      // api 통신
      for (let i = 0; i < 5; i++) {
        if (
          this.bannerList[i].type === "TEXT" &&
          !this.bannerList[i].title.trim() &&
          !this.bannerList[i].titleSub.trim()
        ) {
          this.bannerList[i].display = false;
        }
        this.bannerList[i].priority = i + 1;
      }
      // API requestData 포맷을 위한 불필요한 값 제거
      const sendData = this.bannerList.map((el) => {
        let result = { ...el };
        delete result.updatedAt;
        delete result.createdAt;
        delete result.selectedRow;
        return result;
      });
      const response = this.$Api.modifyBanner(sendData);
      response
        .then(async () => {
          await this.getBannerDisplay();
          EventBus.$emit("open:toast", {
            popup: true,
            message: this.$t("message.banner_setting_complete_confirm"),
          });
          this.isOpenPopup = false;
          this.isDoubleCheck = false;
        })
        .catch((e) => {
          this.isDoubleCheck = false;
          console.log(e.message);
        });
    },
    checkMove() {
      if (this.isDoubleCheck) {
        return false;
      }
    },
    // 선택한 데이터로우의 값 넣기
    setSelectRowData() {
      for (let i = 0; i < 5; i++) {
        if (this.bannerList[i].id == this.selectItemId) {
          this.bannerList[i].selectedRow = true;
          this.title = this.bannerList[i].title;
          this.titleSub = this.bannerList[i].titleSub;
          this.url = this.bannerList[i].url;
          this.type = this.bannerList[i].type;
          this.fileName = this.bannerList[i].fileName;
          this.fileAccessKey = this.bannerList[i].fileAccessKey;
          this.fileNameString = this.bannerList[i].fileNameString;
          this.imgUrl = this.bannerList[i].imgUrl;
        } else {
          this.bannerList[i].selectedRow = false;
        }
      }
    },
    // 사용여부 토글
    toggleDisplay(data) {
      if (!this.isDoubleCheck) {
        for (let i = 0; i < 5; i++) {
          if (this.bannerList[i].id == data.id) {
            this.bannerList[i].display = !data.display;
          }
        }
      }
    },
    //이미지 파일첨부 토글
    toggleTextImg() {
      if (this.type === "TEXT") {
        this.type = "IMG";
      } else {
        this.type = "TEXT";
      }
      this.title = "";
      this.titleSub = "";
      this.fileAccessKey = "";
      this.fileName = "";
      this.imgUrl = "";
    },
    //이미지 업로드
    uploadImage(e) {
      const images = e.target.files || this.$refs["image"].files;
      const exts = /(jpg|jpeg|bmp|gif|png)$/i;
      const maxSize = 30 * 1024 * 1024;
      if (!images) {
        return;
      }
      const imageNames = images[0].name.split(".");
      if (!imageNames || !exts.exec(imageNames[imageNames.length - 1].toLowerCase())) {
        // alert('이미지 파일만 가능');
        return;
      }
      if (images[0].size >= maxSize) {
        // alert('파일크기 30mb이내 가능');
        return;
      }
      const response = this.$Api.uploadFile(images[0]);
      response
        .then((res) => {
          const result = res.data.result;
          if (!result || result.length === 0) {
            return;
          }
          const fileName = result[0].fileName;
          this.fileAccessKey = result[0].fileAccessKey;
          this.fileName = fileName;
          if (fileName && fileName.indexOf(".") != -1) {
            this.fileNameString = fileName.split(".")[0];
          } else {
            this.fileNameString = "";
          }
          const tempUriResponse = this.$Api.getTempUri(this.fileAccessKey);
          tempUriResponse.then((res2) => {
            this.imgUrl = res2.data.result;
          });
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    removeFile() {
      this.fileAccessKey = "";
      this.fileName = "";
      this.imgUrl = "";
    },
  },
};
</script>

<style scoped>
.hide {
  display: none;
}
</style>
