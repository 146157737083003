<template>
  <div class="popup_layer popup_layer_notice">
    <div class="inner_popup_layer">
      <div class="layer_head">
        <strong class="title">{{ _.unescape(noticeDetail.title) }}</strong>
      </div>
      <div class="layer_body">
        <div v-html="computedUnescapeContents"></div>

        <!-- <p>전광판 선택 시 순서변경 및 편집이 가능하며, 미리보기 기능을 통하여 등록될 전광판의 내용을 확인하실 수 있습니다.전광판 선택 시 순서변경 및 편집이 가능하며, 미리보기 기능을 통하여 등록될 전광판의 내용을 확인하실 수 있습니다.전광판 선택 시 순서변경 및 편집이 가능하며, 미리보기 기능을 통하여 등록될 전광판의 내용을 확인하실 수 있습니다.</p> -->
      </div>
      <div class="layer_foot">
        <!-- 추후 추가되는 체크박스 기능 영역, 상단 필독게시판만 해당
        <div class="checkbox_area">
          <Checkbox
            :id="'idsave'"
            :name="'notice'"
            :selectValue.sync="isSaveId"
            :value="true"
          >
            공지확인
          </Checkbox>
        </div>
        -->
        <div class="wrap_btn">
          <button type="button" class="btn_comm" @click="closeNoticeLayer">
            {{ $t("message.btn_close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Checkbox } from "@/components/common/checkbox";
export default {
  name: "NoticeLayer",
  components: {
    Checkbox,
  },
  data() {
    return {
      isSaveId: false,
    };
  },
  computed: {
    ...mapGetters(["noticeDetail"]),

    computedUnescapeContents() {
      return _.unescape(this.noticeDetail.content);
    },
  },
  methods: {
    closeNoticeLayer() {
      this.$emit("closeNoticeLayer");
    },
  },
};
</script>

<style scoped></style>
