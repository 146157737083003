<template>
  <div class="content-feature">
    <h2 class="screen_out">개인화 영역</h2>
    <div class="util_user" :class="{ layer_open: isOpenHeaderLayer }" @click="layerOpen">
      <h3 class="screen_out">계정</h3>
      <span class="ico_comm ico_user"></span>
      <span class="user_info">
        <span class="user_name">{{ userInfo.name }}</span>
        <span class="user_team">{{ userInfo.departmentName }}</span>
        <span class="user_position">
          <span class="txt_position">{{ userInfo.duty }} </span>
          <span v-if="userInfo.position" class="txt_bar"> / </span>
          <span class="txt_team">{{ userInfo.position }}</span>
        </span>
      </span>
      <a href="javascript:void(0);" class="link_user">
        <span class="ico_comm ico_arr">레이어 펼치기</span>
      </a>
      <div class="layer_user">
        <div class="user_profile">
          <div class="user_profile_row">
            <span class="user_profile_emp">{{ userInfo.empNo }}</span>
            <span class="user_profile_rank">
              <template v-if="userInfo.rankCode"> </template>
              {{ userInfo.rankCode }}
            </span>
          </div>
        </div>
        <div class="menu_info">
          <button v-if="isAdmin" type="button" class="btn_admin" @click="openBannerPopup">
            {{ $t("message.banner_setting") }}
          </button>
          <div class="menu_action">
            <a :href="computedChangePassword" target="_blank" class="link_pw">
              {{ $t("message.btn_password_change") }}
            </a>
            <a href="javascript:void(0);" class="link_login" @click="onClickLogout">
              {{ $t("message.btn_logout") }}
            </a>
          </div>
          <button type="button" class="btn_init" @click="initAuthGoogle">
            {{ $t("message.init_google_auth") }}
          </button>
        </div>
      </div>
    </div>
    <div class="util_status">
      <h3 class="screen_out">개인 상세정보</h3>
      <template>
        <!--전자결재 DATA-->
        <div class="status_user">
          <strong class="tit_status"> {{ $t("message.approval") }}</strong>
          <dl class="list_status">
            <dt>
              {{ $t("message.draft") }}<span class="screen_out"> {{ $t("message.document") }}</span>
            </dt>
            <dd>
              <a href="#none" @click.prevent="openApprovalSite('/box/dd')">
                {{ approveCount.draftCount | currency }}
              </a>
            </dd>
          </dl>
          <dl class="list_status">
            <dt>
              {{ $t("message.apprBefore")
              }}<span class="screen_out">{{ $t("message.document") }}</span>
            </dt>
            <dd>
              <a href="#none" @click.prevent="openApprovalSite('/box/ab')">
                {{ approveCount.apprBeforeCount | currency }}
              </a>
            </dd>
          </dl>
          <dl class="list_status">
            <dt>
              {{ $t("message.rejectCount")
              }}<span class="screen_out">{{ $t("message.document") }}</span>
            </dt>
            <dd>
              <a href="#none" @click.prevent="openApprovalSite('/box/dr')">
                {{ approveCount.rejectCount | currency }}
              </a>
            </dd>
          </dl>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { EventBus } from "../../../plugins/eventBus";

import SvgIcon from "@/components/common/SvgIcon.vue";
import IconDearYou from "@/assets/svg/iconDearYou";
import { openApprovalSite } from "@/utils/common";

export default {
  name: "MainHeader",
  components: {
    IconDearYou,
    SvgIcon,
  },
  data() {
    return {
      isOpenHeaderLayer: false,
      isVacationType: false,
      approveCount: {
        draftCount: 0, // 상신한문서
        rejectCount: 0, // 반려된문서
        apprBeforeCount: 0, // 결잿전문서
        receiveBeforeCount: 0, // 수신전문서
        shareCount: 0, // 공람확인문서
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin", "userInfo", "workTime", "userPoint", "userVacation"]),
    computedChangePassword() {
      return process.env.VUE_APP_IAM_ACCOUNT_URL + "/api/accounts/changepassword";
    },
  },
  created() {
    this.getApproval();
  },
  methods: {
    openApprovalSite,
    async getApproval() {
      //전자결재 dataGet
      const result = await this.$Api.getApproval();
      const { status, data, statusText } = result;
      if (status !== 200) {
        EventBus.$emit("open:alert", { data: statusText });
      } else {
        this.approveCount = _.cloneDeep(data.result);
      }
    },
    layerOpen() {
      this.isOpenHeaderLayer = !this.isOpenHeaderLayer;
    },
    changeVacationType() {
      this.isVacationType = !this.isVacationType;
    },
    onClickLogout() {
      EventBus.$emit("open:confirm", {
        message: this.$t("message.logout_message"),
        name: "logout",
        onConfirm: this.logout,
        onCancel: () => {},
      });
    },
    async logout() {
      this.$router.push({ name: "LogoutSuccess" });
    },
    openBannerPopup() {
      EventBus.$emit("open:banner");
    },
    convertVacationValue(val) {
      if (!val || val == undefined) {
        return "0";
      }
      let numberValue = new Number(val);
      if (!this.isVacationType) {
        return numberValue * 8;
      }
      return numberValue;
    },
    initAuthGoogle() {
      const response = axios.patch(`${this.$apiPath.INIT_AUTH_GOOGLE}`);
      response
        .then((res) => {
          location.reload();
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
  },
};
</script>
