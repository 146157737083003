<template>
  <div v-if="isOpen" class="popup_layer">
    <div class="inner_popup_layer">
      <div class="layer_body">
        <p class="txt_popup">
          {{ confirmMessage }}
        </p>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button type="reset" class="btn_comm" @click="cancel">
            {{ $t("message.btn_cancel") }}
          </button>
          <button type="confirm" class="btn_comm btn_comm2" @click="confirm">
            {{ $t("message.btn_confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../plugins/eventBus";

export default {
  name: "Confirm",
  data() {
    return {
      confirmMessage: "", // 컨펌알럿 메세지
      name: "", // 호출한 컴포넌트명
      type: "", // 메뉴타입
      isOpen: false, // 활성황여부
      onConfirm: null,
      onCancel: null,
    };
  },
  mounted() {
    EventBus.$on("open:confirm", this.openConfirm);
  },
  methods: {
    openConfirm(data) {
      this.confirmMessage = data.message;
      this.name = data.name;
      this.type = data.type;
      this.onConfirm = data?.onConfirm;
      this.onCancel = data?.onCancel;
      this.isOpen = true;
    },
    cancel() {
      EventBus.$emit(`cancel:${this.name}`);
      if (typeof this.onCancel === "function") {
        this.onCancel();
      }

      this.isOpen = false;
    },
    confirm() {
      EventBus.$emit(`save:${this.name}`, this.type);
      if (typeof this.onConfirm === "function") {
        this.onConfirm();
      }
      this.isOpen = false;
    },
  },
};
</script>
