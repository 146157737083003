<template>
  <div class="doc-header" role="banner">
    <div class="wrap-title">
      <h1 class="doc-title">
        <span class="logo_helinox">
          <span class="ir_wa">Helinox</span>
        </span>
        <span class="logo_intra">
          <span class="ir_wa">{{ $t("message.intranet") }}</span>
        </span>
      </h1>
    </div>
    <div class="util_userdesc">
      <div
        style="cursor: pointer"
        role="button"
        :aria-expanded="isOpenMenuLayer ? 'true' : 'false'"
      >
        <h2 class="screen_out">사용자 정보 및 개인 설정</h2>
        <div v-show="user.empNo" class="info_user" @click.stop.prevent="onClickToggleMenuLayer">
          <strong class="name_user">{{ userFormat | unescape }}</strong>
          <span class="desc_user">{{ user.departmentName | unescape }}</span>
        </div>
        <div class="wrap_profile">
          <!-- active 클래스로 레이어 토글 -->
          <span
            class="ico_comm link_user"
            :style="
              getProfileUrl
                ? `background:#fff url(${getProfileUrl}) no-repeat 50% 50%;background-size:cover`
                : ''
            "
            @click.stop.prevent="onClickToggleMenuLayer"
          >
            <span class="screen_out">프로필 메뉴</span>
          </span>
          <a href="javascript:;" role="button" class="screen_out" @click="onClickToggleMenuLayer">
            프로필 메뉴 접기
          </a>
          <div class="tooltip_profile" :class="{ active: isOpenMenuLayer }">
            <div class="head_profile">
              <div
                class="ico_comm ico_profile"
                :style="
                  getProfileUrl
                    ? `background:#fff url(${getProfileUrl}) no-repeat 50% 50%;background-size:cover`
                    : ''
                "
              ></div>
              <div class="etc_thumb">
                <dk-badge
                  v-if="user.concurrentList && user.concurrentList.length > 0"
                  :type="'tag_position'"
                  :text="$t('message.concurrent')"
                />
              </div>
              <div class="cont_thumb">
                <strong class="tit_thumb">{{ userFormat | unescape }}</strong>
                <div class="desc_thumb">
                  {{ user.departmentName | unescape }}
                </div>
                <template v-if="user.concurrentList && user.concurrentList.length > 0">
                  <div v-for="(item, idx) in user.concurrentList" :key="idx" class="desc_thumb">
                    {{ item.deptName | unescape }}
                  </div>
                </template>
              </div>
            </div>
            <!-- // head_profile -->
            <div class="box_mode">
              <a href="javascript:;" role="button" class="link_txt" @click.prevent="onClickLogout">
                {{ $t("message.btn_logout") }}
              </a>
            </div>
            <a :href="computedChangePassword" target="_blank" class="link_pw">
              {{ $t("message.btn_password_change") }}
            </a>
            <!-- // box_mode -->
          </div>
        </div>
        <OptComm
          v-model="cmp.selected"
          class="type_lang"
          :options="user.companyList"
          :valueField="'companyId'"
          :nameField="'companyName'"
          :placeholder="'법인 선택'"
          :disabled="user.companyList.length < 2"
          @onSelect="onChangeCompany"
        />
        <OptComm
          v-model="languageCodeSync"
          class="type_lang"
          :options="languageList"
          :label="'언어 선택상자'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Layer from "@/components/common/layer/Layer.vue";
import SvgIcon from "@/components/common/SvgIcon.vue";
import { OptComm } from "@/components/common/select";
import DkBadge from "@/components/badge/DkBadge";

import IconDocument from "@/assets/svg/IconDocument";
import { EventBus } from "../plugins/eventBus";
import {
  LogoIntranet,
  IconMail,
  IconApproval,
  IconCompanyChange,
  IconMessenger,
  IconErpiu,
  IconEdit,
} from "@/assets/svg";

import { setLanguage } from "@/utils/common";

export default {
  name: "AppHeader",
  components: {
    Layer,
    SvgIcon,
    OptComm,
    DkBadge,
    LogoIntranet,
    IconMail,
    IconApproval,
    IconCompanyChange,
    IconMessenger,
    IconErpiu,
    IconEdit,
    IconDocument,
  },
  data() {
    return {
      isOpenMenuLayer: false,
      cmp: {
        selected: null,
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState({
      languageCode: (state) => state.languageCode,
      languageList: (state) => {
        return state.languageList.map((item) => {
          return {
            value: item.code,
            name: item.name,
          };
        });
      },
    }),
    getProfileUrl() {
      return this.user.imgUrl ? this.user.imgUrl : "";
    },
    languageCodeSync: {
      get() {
        return this.languageCode;
      },
      set(value) {
        this.setLanguage(value, this);
      },
    },
    userFormat() {
      if (!this.user) return "";
      return this.user.name + "(" + this.user.position + ")";
    },
    computedChangePassword() {
      return process.env.VUE_APP_IAM_ACCOUNT_URL + "/api/accounts/changepassword";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.isOpenMenuLayer) this.onClickToggleMenuLayer();
      },
    },
    "user.companyId": {
      immediate: true,
      handler(val) {
        this.cmp.selected = val;
      },
    },
    languageCodeSync: {
      immediate: true,
      handler(val) {
        document.title = this.$t("message.intranet", val);
      },
    },
  },
  created() {
    // 프로필 레이어
    document.addEventListener("click", this.onClickUtilClose);
    document.addEventListener("keyup", this.onKeyupUtilClose);
  },
  destroyed() {
    document.removeEventListener("click", this.onClickUtilClose);
    document.removeEventListener("keyup", this.onKeyupUtilClose);
  },
  methods: {
    setLanguage,
    openDocumentPopup() {
      EventBus.$emit("open:document");
    },
    onClickUtilClose(event) {
      if (
        document.getElementsByClassName("wrap_profile")[0] &&
        !document.getElementsByClassName("wrap_profile")[0].contains(event.target)
      ) {
        if (this.isOpenMenuLayer) this.isOpenMenuLayer = false;
      }

      event.stopPropagation();
    },
    onKeyupUtilClose() {
      if (this.isOpenMenuLayer) this.isOpenMenuLayer = false;
      EventBus.$emit("status:openSelect", "");
    },
    onClickToggleMenuLayer() {
      EventBus.$emit("status:openSelect", "");

      const layer = document.querySelectorAll(".comm_layer");
      if (layer.length > 0) return false;

      this.isOpenMenuLayer = !this.isOpenMenuLayer;
    },
    async onChangeCompany(selectCompanyId) {
      const result = await this.$Api.companySelect({
        companyId: selectCompanyId,
        vueUrl: window.location.origin,
      });
      window.location.href = result.data;
    },
    onClickLogout() {
      this.isOpenMenuLayer = false;

      EventBus.$emit("open:confirm", {
        message: this.$t("message.logout_message"),
        name: "logout",
        onConfirm: this.logout,
        onCancel: () => {},
      });
    },
    async logout() {
      this.$router.push({ name: "LogoutSuccess" });
    },
  },
};
</script>
