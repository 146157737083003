const SessionStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setIsPc(val) {
    sessionStorage.setItem("is_pc", val);
  }
  function _getIsPc() {
    return sessionStorage.getItem("is_pc");
  }
  function _clearIsPc() {
    sessionStorage.removeItem("is_pc");
  }
  function _setIsApp(val) {
    sessionStorage.setItem("is_app", val);
  }
  return {
    getService: _getService,
    setIsPc: _setIsPc,
    getIsPc: _getIsPc,
    clearIsPc: _clearIsPc,
    setIsApp: _setIsApp,
  };
})();
export default SessionStorageService;
