<template>
  <div>
    <div v-if="!isEditMenu">
      <span v-if="isEdit" class="ico_comm ico_move">이동</span>
      <span class="item_txt" @click="moveUrl(item.url)">
        <span class="txt_item">
          <span v-if="item.open && !isFavorite" class="badge_txt">OPEN</span>
          {{ item.name }}
        </span>
      </span>
      <span v-if="(isFavorite || isAdmin) && isEdit" class="wrap_btn">
        <button type="button" class="btn_cate" @click="changeEditMenu">
          <span class="ico_comm ico_edit">{{ $t("message.btn_correction") }}</span>
        </button>
        <button type="button" class="btn_cate" @click="removeMenu(type, item.priority)">
          <span class="ico_comm ico_trash">{{ $t("message.btn_delete") }}</span>
        </button>
      </span>
    </div>
    <div v-if="isEditMenu">
      <div class="item_add">
        <span class="wrap_inp">
          <span class="item_inp_type1" :class="{ error_inp: isErrorTitle }">
            <input
              v-model="inputTitle"
              type="text"
              :placeholder="$t('message.content_L_1')"
              class="inp_comm"
              title="표기문구 입력"
              maxlength="17"
            />
          </span>
        </span>
        <span class="wrap_inp">
          <span class="item_inp_type1" :class="{ error_inp: isErrorTitleSub }">
            <input
              v-model="inputUrl"
              type="text"
              :placeholder="$t('message.banner_L_4')"
              class="inp_comm"
              title="인터넷주소 입력"
            />
          </span>
        </span>
        <p v-show="isErrorTitle || isErrorTitleSub" class="txt_error">{{ errorText }}</p>
        <button class="btn_cate btn_confirm" @click="modifyMenu">
          <span class="ico_comm ico_confirm">{ $t('message.btn_confirm') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Validate from "../../../plugins/Validate";

export default {
  name: "EditInput",
  props: ["item", "type", "isEdit", "isFavorite", "isEditMenu"],
  data() {
    return {
      inputTitle: "", // favorite 타이틀
      inputUrl: "", // favorite Url
      isErrorTitle: false, // 애러문구 노출여부
      isErrorTitleSub: false, // 애러문구 노출여부
      errorText: "", // 애러문구
      isBadUrl: false, // Url 유효성
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  mounted() {
    this.inputTitle = this.item.name;
    this.inputUrl = this.item.url;
  },
  methods: {
    changeEditMenu() {
      this.inputTitle = this.item.name;
      this.inputUrl = this.item.url;
      this.$emit("openeditmenu", this.item.priority);
    },
    modifyMenu() {
      if (!this.inputTitle.trim() && !this.inputUrl.trim()) {
        this.isErrorTitle = true;
        this.isErrorTitleSub = true;
        this.errorText = this.$t("message.content_validation");
      } else {
        if (!this.inputTitle.trim()) {
          this.isErrorTitle = true;
          if (!Validate.urlPattern(this.inputUrl)) {
            this.isErrorTitleSub = true;
            this.errorText = this.$t("message.content_notation_url_validation");
          } else {
            this.errorText = this.$t("message.content_notation_validation");
          }
        } else {
          this.isErrorTitle = false;
        }
        if (!this.inputUrl.trim()) {
          this.isErrorTitleSub = true;
          this.errorText = this.$t("message.content_address_validation");
        } else {
          if (this.inputTitle.trim() && !Validate.urlPattern(this.inputUrl)) {
            this.isErrorTitle = false;
            this.isErrorTitleSub = true;
            this.errorText = this.$t("message.content_url_validation");
          } else {
            this.isErrorTitleSub = false;
          }
        }
      }

      if (!this.inputTitle.trim() || !this.inputUrl.trim() || !Validate.urlPattern(this.inputUrl)) {
        return false;
      } else {
        this.errorText = "";
      }

      let tempItem = {
        type: this.item.type,
        name: this.inputTitle,
        url: this.inputUrl,
        open: this.item.open,
        deleteFlag: this.item.deleteFlag,
        priority: this.item.priority,
      };
      this.$emit("initedit", tempItem);
    },
    removeMenu(type, priority) {
      this.$emit("removeMenu", { type: type, priority: priority });
    },
    moveUrl(url) {
      if (!this.isEdit) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.item_txt {
  cursor: pointer;
}
</style>
