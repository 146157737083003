<template>
  <div class="item_form">
    <div
      ref="optComm"
      v-docClick="getEvtTargetBlur"
      v-docFocusIn="getEvtTargetBlur"
      class="select_comm"
      :class="[
        {
          opt_open: isOpen && !disabled,
          opt_hide: isHide,
        },
      ]"
      :aria-disabled="disabled"
    >
      <strong v-if="optDesc" class="screen_out">{{ optDesc }}</strong>
      <a
        :id="id"
        ref="linkOpt"
        href="#none"
        class="link_selected"
        :class="[{ disabled: disabled, error: error }]"
        :aria-expanded="isOpen.toString()"
        @click="
          (e) => {
            setToggle('toggle');
            e.preventDefault();
          }
        "
        @keydown.down.prevent="getKeyFocus('first', 0)"
        @keydown.tab="setToggle(false)"
        @keydown.esc="setToggle(false)"
        ><!-- 오픈시 true, 닫혔을 때 false -->
        <span class="ico_comm ico_select">선택됨</span>
        {{ displaySelName | unescape }}
      </a>
      <input type="hidden" :value="curValue" />
      <div v-if="options && options.length > 0" class="box_opt" @keydown="getEvtFocusScrollStop">
        <ul class="list_opt" role="listbox">
          <li
            v-for="(option, index) in options"
            :key="`${option[nameField]}${index}${option[valueField]}`"
            role="option"
            :class="{ on: isSelected(option) }"
            :title="option[nameField]"
          >
            <a
              ref="linkOption"
              href="#none"
              class="link_opt"
              :class="{ on: isSelected(option) }"
              :aria-selected="isSelected(option).toString()"
              @click.prevent="
                (e) => {
                  setOption(option, index);
                  e.preventDefault();
                }
              "
              @keydown.up.prevent="getKeyFocus('up', index)"
              @keydown.down.prevent="getKeyFocus('down', index)"
              @keydown.esc="getKeyFocus('exit')"
            >
              <span class="txt_opt">
                {{ option[nameField] | unescape
                }}<!-- 엔티티 코드를 제대로 노출 못하는 이슈 때문에 빈 span 내 v-html로 변경 -->
                <span v-if="subNameField" class="txt_add">{{
                  option[subNameField] | unescape
                }}</span>
              </span>
              <span v-if="isSelected(option)" class="screen_out">선택됨</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 공통 셀렉트 박스 컴포넌트
 * @displayName opt-comm
 */

export default {
  name: "SelectComm",
  props: {
    /**
     * .link_opt 에 id 속성 추가.
     */
    id: String,
    /**
     * 부모의 v-model에 값 연결
     */
    value: [String, Number, Object, Array],
    /**
     * 옵션 배열
     */
    options: Array,
    /**
     * 옵션 텍스트로 노출 할 object key 값
     */
    nameField: {
      type: String,
      default: "name",
    },
    /**
     * 옵션 텍스트 추가 설명으로 노출 할 object key 값
     */
    subNameField: {
      type: [String],
      default: "",
    },
    /**
     * 옵션 선택 시 받을 object key 값
     */
    valueField: {
      type: String,
      default: "value",
    },
    /**
     * 최초 노출 선택 텍스트
     */
    selName: {
      type: [String, Number],
      default: "",
    },
    /**
     * 접근성 라벨 텍스트
     */
    optDesc: String,
    /**
     * disabled 여부(.link_selected에 클래스 추가)
     * @values true, false
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 출력할 서브 텍스트
     */
    txtSub: String,
    /**
     * error 여부(.link_selected에 클래스 추가)
     * @values true, false
     */
    error: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isHide: true,
      displaySelName: "",
      curIdx: null,
      curValue: "",
    };
  },
  computed: {
    /**
     * 포커스 대상 옵션의 인덱스를 찾아서 반환
     * @return {number}
     */
    getFocusKeyIdx() {
      const findFocusKey = (opt) => opt[this.nameField] === this.displaySelName;
      return this.options.findIndex(findFocusKey);
    },
  },
  mounted() {
    // 기본 선택 텍스트 노출
    this.displaySelName = this.selName || this.$t("message.label_choice");
    this.setWatchName();
    this.setWatchOptions();
    this.setWatchCbChange();
  },
  methods: {
    isSelected(option) {
      return option[this.nameField] === this.displaySelName;
    },
    /**
     * 셀렉트박스 토글
     */
    setToggle(type, targetType = "") {
      this.isOpen = type === "toggle" ? !this.isOpen : type;
      this.setCurKeyFocus(targetType);
    },
    /**
     * 포커스 대상 옵션 포커싱
     * @param type
     * @return {boolean}
     */
    setCurKeyFocus(type) {
      const keyIdx = this.getFocusKeyIdx;
      // 박스가 열려있지 않으면 포커싱 하지 않음.
      if (!this.isOpen) return false;

      // 옵션이 없으면 포커싱하지 않음.
      if (this.options.length === 0) return false;

      // 키보드로 이동하는 경우 opt_hide 클래스 적용 해제.(포커스 이벤트 정상작동 안함)
      if (type === "FIRST") {
        this.isHide = false;
      }

      // 박스가 열리고 포커싱해야 하기 때문에 Timeout에서 실행
      setTimeout(() => {
        // 포커스 대상이 있으면 포커싱
        if (keyIdx > 0) {
          this.$refs.linkOption[keyIdx].focus();

          // 포커스 대상은 없지만 키보드로 첫번째 옵션으로 이동하려고 하는 경우 index 0짜리 포커싱
        } else if (type === "FIRST") {
          this.$refs.linkOption[0].focus();
        }
      }, 0);
      setTimeout(() => {
        this.isHide = false;
      }, 10);
    },
    // 옵션 선택 시
    setOption(option, index) {
      this.displaySelName = option[this.nameField];
      this.setToggle(false);
      this.$refs.linkOpt.focus();
      this.curValue = option[this.valueField];
      this.curIdx = index;
      /**
       * v-model 전달 이벤트<br>(선택한 option[this.valueField] 반환)
       * @property {string} curValue 선택한 옵션 value
       */
      this.$emit("input", this.curValue);
      /**
       * 콜백<br>(선택한 option object 반환)
       * @property {object} option 선택한 옵션 object
       */
      this.$emit("cb", option, this);
      // this.$emit('cb', option, this, this.cbPayload); // 콜백 payload 필요하면 사용하세요!
    },
    // 인풋 이외 영역 클릭 시
    getEvtTargetBlur(e) {
      const el = this.$refs.optComm;
      const target = e.target;
      // 라우터 이동 타이밍에 호출이 되는 이슈가 있어 _isDestroyed 검사 추가
      if (!this._isDestroyed && el !== target && !el.contains(target)) {
        this.isOpen = false;
      }
    },
    // 키보드 키로 위 아래 포커스 제어 시 기본 스크롤 이벤트 막기
    getEvtFocusScrollStop(e) {
      // 위 아래 키보드 키
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
    },
    // 키보드 포커스 이벤트 시
    getKeyFocus(type, index) {
      switch (type) {
        case "up":
          if (index === 0) {
            this.getKeyFocus("exit");
            return false;
          }
          this.$refs.linkOption[index - 1].focus();
          break;

        case "down":
          if (this.options.length - 1 <= index) return false;
          this.$refs.linkOption[index + 1].focus();
          break;

        case "first":
          this.setToggle(true, "FIRST");
          break;

        case "exit":
          this.$refs.linkOpt.focus();
          this.setToggle(false);
          break;
      }

      return false;
    },
    // 부모에서 이름을 강제로 업데이트 할때 사용.
    updateName(name) {
      this.displaySelName = name;
    },
    setSelectValue(val) {
      const _val = val || this.value;
      // value와 valueField가 일치하는 옵션을 찾아 반환
      const selected = this.options.filter((opt) => opt[this.valueField] === _val);
      // 해당 옵션의 nameField를 display 함.
      if (selected.length > 0) {
        this.displaySelName = selected[0][this.nameField];
      } else {
        this.displaySelName = this.selName || this.$t("message.label_choice");
      }
      /**
       * 옵션 선택 시 발생 이벤트
       */
      this.$emit("click");
    },
    // watchName props 활성 시 실행
    setWatchName() {
      this.$watch(
        "value",
        (val) => {
          this.setSelectValue(val);
        },
        { immediate: true },
      );
    },
    // watchName props 활성 시 실행
    setWatchOptions() {
      this.$watch(
        "options",
        () => {
          this.setSelectValue();
        },
        { immediate: false },
      );
    },
    // 모바일의 @cb 이벤트와 동일한 시점에 실행되는 @cb-change 이벤트 사용
    setWatchCbChange() {
      this.$watch(
        "value",
        (val) => {
          /**
           * 모바일의 @cb 이벤트와 동일한 시점에 실행되는 @cb-change 이벤트
           * @property {string} curValue 선택한 옵션 value
           */
          this.$emit("on-change", val);
        },
        { immediate: false },
      );
    },
  },
};
</script>
