// auth
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_PERMISSION = "SET_PERMISSION";
export const SET_WORK_TIME = "SET_WORK_TIME";
export const SET_EVENT_HOLIDAY = "SET_EVENT_HOLIDAY";
export const SET_USER_POINT = "SET_USER_POINT";
export const SET_USER_VACATION = "SET_USER_VACATION";
export const SET_WEATHER = "SET_WEATHER";
export const SET_BANNER = "SET_BANNER";
export const SET_BANNER_DISPLAY = "SET_BANNER_DISPLAY";
export const SET_WORK_MENU_LIST = "SET_WORK_MENU_LIST";
export const SET_OFFICE_MENU_LIST = "SET_OFFICE_MENU_LIST";
export const SET_FAVORITE_MENU_LIST = "SET_FAVORITE_MENU_LIST";

export const SET_APPROVAL = "SET_APPROVAL"; //전자결제 data set

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"; //언어변경
export const SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST"; //언어리스트

export const SET_NOTICE_LIST = "SET_NOTICE_LIST"; //공지사항 목록
export const SET_NOTICE_DETAIL = "SET_NOTICE_DETAIL"; //공지사항 상세

export const SET_MAIL_LIST = "SET_MAIL_LIST"; // 메일 목록
export const SET_MAIN_EVENT_LIST = "SET_MAIN_EVENT_LIST"; // 주요 일정 목록
export const SET_TODAY_EVENT_LIST = "SET_TODAY_EVENT_LIST"; // 오늘의 일정 목록

export const SET_APPROVAL_LIST = "SET_APPROVAL_LIST"; //  결재할 문서 목록
