<template>
  <!-- 탭 메뉴 -->
  <ul class="list_tab" role="tablist">
    <tab-item
      v-for="(item, idx) in list"
      :key="idx"
      :item="item"
      :selected="value === idx"
      @click="select(idx, item)"
    >
    </tab-item>
  </ul>
</template>
<script>
import TabItem from "./TabItem";

/**
 * 탭 메뉴 컴포넌트
 * @displayName tab-list
 */

export default {
  name: "TabList",
  components: {
    TabItem,
  },
  props: {
    /**
     * 활성탭 index
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * 탭 리스트
     */
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    /**
     * 탭 선택에 따른 이벤트 전달
     * @param seq
     * @param item
     */
    select(seq, item) {
      /**
       * 탭 선택 시 selected.async 업데이트
       * @property {Number} page 탭 seq
       */
      this.$emit("input", seq);
      /**
       * 탭 선택하여 변경 시 change 이벤트
       * @property {Number} item 선택한 탭 item
       */
      this.$emit("change", item);
    },
  },
};
</script>
