// axios 공용 interceptors
import Vue from "vue";
import router from "../router";
import LocalStorageService from "../plugins/LocalStorageService";
import { checkChangeEmpLang } from "@/utils/common";

const axios = Vue.axios;
const localStorageService = LocalStorageService.getService();

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorageService.getAccessToken();

    // 로컬 스토리지에 토큰이 없을 시
    // 'Bearer ACCESS_TOKEN' 값을 키로 넘김
    // TODO access token값이 없이는 message에 null 값이 떨어지는데 그 부분 문의해서 수정
    // config.headers['Authorization'] = token ? token : 'Bearer ACCESS_TOKEN';
    if (token) {
      config.headers["Authorization"] = token;
    }
    //config.withCredentials = true;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // login success(local storage 저장)
    if (response.config.url === "/api/login") {
      const { authorization, refreshtoken } = response.headers;
      localStorageService.setToken({
        access_token: authorization,
        refresh_token: refreshtoken,
      });
      checkChangeEmpLang();
    }
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    const originalRequest = error.config;
    // console.log('error.response', error.response);

    if (error.response.status === 204) {
      // No Content
    }

    // access_token으로 인증실패시
    if (error.response.status === 401) {
      localStorageService.clearToken();
      router.push("/login");
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      // Forbidden
      alert(error.message);
    }

    if (error.response.status === 404) {
      // Not Found
      alert(error.message);
    }

    if (error.response.status === 500) {
      // 로그인 실패시
      if (
        error.response.data.message ===
          "io.dktechin.core.exception.UnauthorizedException: Expired token" ||
        error.response.data.message ===
          "io.dktechin.core.exception.UnauthorizedException: Token parse error"
      ) {
        // 토큰 재발급 실패
        if (originalRequest.url === "/api/token/reissuance") {
          localStorageService.clearToken();
          router.push("/login");
          return Promise.reject(error);
        }
        // 토큰 재발급 요청
        const refreshToken = localStorageService.getRefreshToken();
        return axios.post("/api/token/reissuance", { refresh_token: refreshToken }).then((res) => {
          if (res.status === 200) {
            localStorageService.setToken(res.data);
            checkChangeEmpLang();
            axios.defaults.headers.common["Authorization"] = localStorageService.getAccessToken();
            return axios(originalRequest);
          }
        });
      } else {
        alert(error.message);
        //router.push("/login");
      }
    }
    return Promise.reject(error);
  },
);
