<template>
  <div class="calendar_box">
    <flat-pickr
      v-if="isRender"
      :id="id"
      ref="flatPickerRef"
      v-model="computedValue"
      :config="flatConfig"
      :disabled="isDisabled"
      :placeholder="hasPlaceholder"
      :readonly="isReadonly"
      :events="flatEvents"
      class="tf_comm"
      type="text"
      @on-open="onFocus"
      @on-close="onBlur"
      @on-day-create="onDayCreate"
    />
    <span class="calendar_ico" @click="clickIcon"></span>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
//import {I18N} from '../../../translations/type';
import { EventBus } from "@/plugins/eventBus";
import jquery from "jquery";
// import { API_LIST } from '../../../api';

export const DATE_PICKER_MODE = {
  SINGLE: "single",
  MULTIPLE: "multiple",
  RANGE: "range",
};

export const DATE_PICKER_SIZE = {
  MEDIUM: "form_medium",
  FULL: "",
};

export default {
  name: "DatePicker",
  components: {
    flatPickr,
  },
  props: {
    /**
     * 값 (모델 바인딩)
     * @prop {Number, String, Array} value
     * @default null
     */
    value: {
      type: [Number, String, Array],
      default: null,
    },
    /**
     * id 속성 바인딩
     * @prop {String} id
     * @default null
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * name 속성 바인딩
     * @prop {String} name
     * @default null
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * placeholder 속성 바인딩
     * @prop {String} placeholder
     * @default ''
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * disabled 속성 바인딩
     * @prop {String, Boolean} disabled
     * @default null
     */
    disabled: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * readonly 속성 바인딩
     * @prop {String, Boolean} readonly
     * @default false
     */
    readonly: {
      type: [String, Boolean],
      default: false,
    },
    /**
     * inline 옵션 (flatpickr)
     * @prop {Boolean} inline
     * @default false
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * 유효성 오류 여부
     * @prop {Boolean} isError
     * @default false
     */
    isError: {
      type: Boolean,
      default: false,
    },
    /**
     * 오류 메시지
     * @prop {String} txtError
     * @default ''
     */
    txtError: {
      type: String,
      default: "",
    },
    /**
     * 서브 메시지
     * (단, 에러 메시지가 있는 경우, 에러 메시지가 먼저 노출)
     * @prop {String} txtSub
     * @default ''
     */
    txtSub: {
      type: String,
      default: "",
    },
    /**
     * disableDates 옵션 바인딩 (flatpickr)
     * @prop {Array} disableDates
     * @default []
     */
    disableDates: {
      type: Array,
      default: () => [],
    },
    /**
     * maxDate 옵션 바인딩 (flatpickr)
     * @prop {String} maxDate
     * @default null
     */
    maxDate: {
      type: [String, Date],
      default: null,
    },
    /**
     * minDate 옵션 바인딩 (flatpickr)
     * @prop {String} minDate
     * @default null
     */
    minDate: {
      type: [String, Date],
      default: null,
    },
    /**
     * 기본 입력 값 바인딩 (flatpickr)
     * @prop {String} defaultValue
     * @default null
     */
    defaultValue: {
      type: String,
      default: null,
    },
    /**
     * mode 옵션 바인딩  (flatpickr)
     * @prop {String} mode
     * @default DATE_PICKER_MODE.SINGLE
     */
    mode: {
      type: String,
      default: DATE_PICKER_MODE.SINGLE,
      validator: (value) =>
        Object.keys(DATE_PICKER_MODE).find((key) => DATE_PICKER_MODE[key] === value),
    },
    /**
     * 크기 옵션
     * @prop {String} size
     * @default DATE_PICKER_SIZE.MEDIUM
     */
    size: {
      type: String,
      default: DATE_PICKER_SIZE.MEDIUM,
      validator: (value) =>
        Object.keys(DATE_PICKER_SIZE).find((key) => DATE_PICKER_SIZE[key] === value),
    },
    /**
     * 강제 입력방지
     */
    nullIgnore: {
      type: Boolean,
      default: false,
    },
    holidayList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      newValue: this.value,
      cloneValue: "",
      isFocused: false,
      isRender: true,
      // Initial value
      date: new Date(),
      // Get more form https://chmln.github.io/flatpickr/options/
      flatConfig: {},
      flatEvents: ["onDayCreate", "onOpen", "onClose"],
      config: {
        inline: this.inline,
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: "Y.m.d",
        dateFormat: "Y-m-d",
        locale: "", // locale for this instance only
        altInput: true,
        position: "below",
        mode: this.mode, // "single", "multiple", or "range"
        maxDate: this.maxDate,
        minDate: this.minDate,
        prevArrow: `<span class="ico_comm ico_arr_l">이전</span>`,
        nextArrow: `<span class="ico_comm ico_arr_l">다음</span>`,
        disable: this.disableDates,
        plugins: [],
      },
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isDisabled() {
      if (this.disabled || this.disabled === "" || this.disabled === "disabled") return true;
      return false;
    },
    isReadonly() {
      if (this.readonly || this.readonly === "" || this.readonly === "readonly") return true;
      return false;
    },
    hasPlaceholder() {
      if (this.placeholder === "YYYY.MM.DD") return "YYYY-MM";
      return this.placeholder || "일자 선택";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.nullIgnore) {
          if (!value && this.cloneValue) {
            EventBus.$emit("open:alert", {
              title: "일자를 선택해 주세요",
              name: "dateNullIgnore",
              onConfirm: this.rollback,
            });
          }
        }
        this.newValue = value;
        if (value) this.cloneValue = value;
      },
    },
    currentLanguage: {
      immediate: true,
      handler() {
        this.isRender = false;
        setTimeout(() => {
          this.isRender = true;
        }, 10);
        this.init();
      },
    },
    minDate: {
      immediate: true,
      handler(data) {
        this.config.minDate = data;
        if (moment(data, "YYYY-MM-DD").isAfter(moment(this.computedValue, "YYYY-MM-DD")))
          this.computedValue = "";
        this.isRender = false;
        setTimeout(() => {
          this.isRender = true;
        }, 10);
        this.init();
      },
    },
    maxDate: {
      immediate: true,
      handler(data) {
        this.config.maxDate = data;
        if (moment(data, "YYYY-MM-DD").isBefore(moment(this.computedValue, "YYYY-MM-DD")))
          this.computedValue = "";
        this.isRender = false;
        setTimeout(() => {
          this.isRender = true;
        }, 10);
        this.init();
      },
    },
    holidayList: {
      immediate: true,
      handler(data) {
        this.isRender = false;
        setTimeout(() => {
          this.isRender = true;
        }, 10);
        this.init();
      },
    },
  },
  created() {
    this.init();
    if (!this.newValue && this.defaultValue) this.computedValue = this.defaultValue;
  },
  destroyed() {},
  methods: {
    clickIcon() {
      this.$refs.flatPickerRef.fp.open();
    },
    init() {
      const lang = "ko";

      this.langPack(lang === "en" ? "default" : lang);
      this.config.locale = lang === "en" ? "default" : lang;
      this.config.prevArrow = `<span class="ico_comm ico_arr_l">이전</span>`;
      this.config.nextArrow = `<span class="ico_comm ico_arr_l">다음</span>`;

      this.flatConfig = this.config;
    },
    onBlur() {
      const layer = document.querySelectorAll(".comm_layer");
      if (layer.length > 0) {
        // console.log('remove');
        jquery(".comm_layer").off("scroll");
      }
    },
    onFocus() {
      this.$nextTick(() => {
        const layer = document.querySelectorAll(".comm_layer");
        const flatpickr = document.querySelectorAll(".flatpickr-calendar");
        if (layer.length > 0) {
          flatpickr.forEach((item) => {
            item.classList.add("__layer-up");
            const top = parseInt(document.body.style.top.replace("px", ""));
            if (top < 0) {
              item.style.marginTop = top * -1 + "px";
            }
            setTimeout(() => {
              jquery(".comm_layer").on("scroll", () => {
                const layerTop = jquery(".comm_layer")[0].scrollTop;
                document.querySelectorAll(".flatpickr-calendar").forEach((picker) => {
                  if (top < 0) {
                    picker.style.marginTop = (top + layerTop) * -1 + "px";
                  }
                });
              });
            }, 10);
          });
        } else {
          flatpickr.forEach((item) => {
            item.classList.remove("__layer-up");
            item.style.marginTop = null;
          });
        }
      });
      if (this.isError) this.$emit("update:isError", false);
    },
    onDayCreate(dObj, dStr, fp, dayElem) {
      /*
       * 공휴일 색상 표기 기능
       * holidayList props로 배열 가져와서 처리,
       * holidayList는 ['YYYY-MM-DD'] 형태로 내려 받아야 됨
       */
      const isHoliday =
        this.holidayList.indexOf(moment(dayElem.dateObj).format("YYYY-MM-DD")) !== -1;
      if (isHoliday) {
        dayElem.className += " flatpickr-holiday";
      }
    },
    langPack(language) {
      return require(`flatpickr/dist/l10n/${language}.js`).default[language];
    },
    rollback() {
      this.isRender = false;
      this.computedValue = this.cloneValue;
      setTimeout(() => {
        this.isRender = true;
      }, 10);
    },
  },
};
</script>
