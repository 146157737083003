import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";
import MobileDetect from "mobile-detect";
import LocalStorageService from "../plugins/LocalStorageService";
import SessionStorageService from "../plugins/SessionStorageService";
import { checkChangeEmpLang } from "@/utils/common";

const localStorageService = LocalStorageService.getService();
const sessionStorageService = SessionStorageService.getService();

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",

    meta: { authRequired: false },
    component: () => import("@/views/Login.vue"),
  },

  {
    path: "/loginSuccess",
    name: "loginSuccess",
    meta: { authRequired: false },
    component: () => import("@/views/login/LoginSuccess.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Home,

    meta: { authRequired: true },
  },

  {
    path: "/selectCompany",
    name: "selectCompany",
    component: () => import("@/views/login/LoginCompany.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/LogoutSuccess",
    name: "LogoutSuccess",
    meta: { authRequired: true },
    component: () => import("@/views/login/LogoutSuccess.vue"),
  },
  {
    path: "/googleSighInSuccess",
    name: "GoogleSighInSuccess",
    meta: { authRequired: true },
    component: () => import("@/views/login/GoogleSighInSuccess.vue"),
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  store,
  routes,
});

const mobileDetect = new MobileDetect(window.navigator.userAgent);

router.beforeEach(async function (to, from, next) {
  const isPc = to.query.isPc;
  if (isPc) {
    sessionStorageService.setIsPc(true);
  }
  if (mobileDetect.mobile()) {
    if (!sessionStorageService.getIsPc()) {
      location.href = `${process.env.VUE_APP_MOBILE_URL}`;
    }
  }
  // 이동할 url에 해당하는 라우팅 객체에서 auth chek
  const isAuthRequired = to.matched.some((routeInfo) => routeInfo.meta.authRequired !== false);

  if (!!to.query.accessToken && !!to.query.refreshToken) {
    localStorageService.setToken({
      access_token: to.query.accessToken,
      refresh_token: to.query.refreshToken,
    });
  }

  const accessToken = localStorageService.getAccessToken();
  const casFlag = localStorageService.getCasFlag();

  // 언어 설정
  checkChangeEmpLang();

  if (to.query.retmsg || to.query.authority) {
    next();
  } else {
    if (!accessToken) {
      // 로컬스토리지 토큰 여부 체크 (토큰 없음)
      if (!casFlag) {
        // CAS 로그인 체크 (init)
        const casUrl = process.env.VUE_APP_PROXY_CAS_API;
        const vueUrl = window.location.origin;
        const apiUrl = process.env.VUE_APP_API_URL;

        const rl = `${vueUrl}/login&rl=${apiUrl}/api/login/auth&vueUrl=${vueUrl}`;
        const serviceUrl = `${apiUrl}/api/login/cas?loginUrl=${encodeURIComponent(rl)}`;
        console.log(serviceUrl);

        localStorageService.setCasFlag(true);
        // console.log(`${casUrl}?service=${serviceUrl}`);

        window.location.href = `${casUrl}?service=${serviceUrl}`;
      } else {
        // CAS 로그인 체크 (카스 다녀옴)
        localStorageService.clearCasFlag();
        next();
      }
    } else {
      // 로컬스토리지 토큰 여부 체크 (토큰 있음)
      localStorageService.clearCasFlag();
      next();

      if (isAuthRequired) {
        //메뉴 권한체크 (메뉴권한있음)
        next();
      } else {
        if (to.path === "/login") {
          next("/");
        } else {
          next();
        }
      }
    }
  }
});

/*

router.beforeEach(async function (to, from, next) {
  const isPc = to.query.isPc;
  if (isPc) {
    sessionStorageService.setIsPc(true);
  }

  if (mobileDetect.mobile()) {
    if (!sessionStorageService.getIsPc()) {
      location.href = `${process.env.VUE_APP_MOBILE_URL}`;
    }
  }
  // 이동할 url에 해당하는 라우팅 객체에서 auth chek
  const isAuthRequired = to.matched.some((routeInfo) => routeInfo.meta.authRequired);
  const accessToken = localStorageService.getAccessToken();
  if (isAuthRequired) {
    if (accessToken) {
      console.log('routing success : "' + to.path + '"');
      next();
    } else {
      if (!!to.query.accessToken && !!to.query.refreshToken) {
        // 로인이 성공한 상태
        localStorageService.setToken({
          access_token: to.query.accessToken,
          refresh_token: to.query.refreshToken,
        });
        next({ name: to.name });
      } else {
        next("/login");
      }
    }
  } else {
    if (to.path === "/login" && accessToken) {
      next("/");
    } else {
      next();
    }
  }
});
*/

export default router;
